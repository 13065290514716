import React from "react";

function Medal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "511pt"}
      height={props.height || "511pt"}
      version="1.1"
      viewBox="-66 0 511 511.999"
    >
      <path
        fill="#d40000"
        d="M75.781 328.71L11.5 442l76.96-15.98L115.419 502 190.5 370zm0 0"
      ></path>
      <path
        fill="#d40000"
        d="M305.219 328.71L190.5 370l75.078 132 26.961-75.98L369.5 442zm0 0"
      ></path>
      <path
        fill="#ffda2d"
        d="M190.5 10c-99.41 0-180 80.59-180 180 0 99.406 80.586 180 180 180 99.39 0 180-80.57 180-180 0-99.41-80.594-180-180-180zm0 0"
      ></path>
      <g
        fill="#1a1a1a"
        fillRule="evenodd"
        stroke="none"
        transform="matrix(12.13428 0 0 12.13428 -1931.185 -1708.545)"
      >
        <path
          strokeWidth="1"
          d="M18.639199 35.629797L17 31.127574 47 10 31.942474 44 27.339265 43.124476 27.851058 40.28803 30.29421 40.752514 40.262176 18.243618 20.40152 32.230386 21.271276 34.619762z"
          transform="matrix(.75147 0 0 .75147 152.148 133.867)"
        ></path>
        <path
          strokeWidth="1"
          d="M12.050907 49L12.214583 48.823169 23.678671 41.449274 25 35 18.20519 37.296506 12.16432 48.785006 12 48.961254 12.107936 48.89192z"
          transform="matrix(.75147 0 0 .75147 152.148 133.867)"
        ></path>
        <path
          strokeWidth="1"
          d="M48.055621 24.813816L45 24.422636 47.244428 26.553106 45.931559 29.366922 48.631509 27.86953 50.875938 30 50.299747 26.944286 53 25.446894 49.944078 25.055714 49.368491 22z"
          transform="matrix(.75147 0 0 .75147 152.148 133.867)"
        ></path>
        <path
          strokeWidth="0.751"
          d="M174.812 142.779c1.233 0 2.428.16 3.565.454l2.327-1.523a16.484 16.484 0 00-5.892-1.08c-8.767 0-15.9 6.803-15.9 15.165 0 2.99.912 5.78 2.484 8.13l1.182-2.37a12.446 12.446 0 01-1.413-5.76c0-7.177 6.122-13.016 13.647-13.016"
        ></path>
        <path
          strokeWidth="0.751"
          d="M187.395 160.168c-1.43 5.669-6.885 9.902-13.38 9.902-1.326 0-2.608-.177-3.822-.506l-2.264 1.505a16.9 16.9 0 006.086 1.123c7.753 0 14.237-5.175 15.707-12.024z"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#1a1a1a"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="20.064"
        d="M75.781 328.71L11.5 442l76.96-15.98L115.419 502 190.5 370l75.078 132 26.961-75.98L369.5 442l-64.281-113.29"
      ></path>
    </svg>
  );
}

export default Medal;
