import React from "react";
import { useGlobalState } from "../../store";

export default props => {
  const [pageClass] = useGlobalState("pageClass");
  return (
    <div className={`page ${pageClass}`}>
      <div>{props.children}</div>
    </div>
  );
};
