import { Box, Flex, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useStopwatch } from "react-timer-hook";
import ChooseUserModal from "../../users/ChooseUserModal";
import PowerIcon from "./PowerIcon";
import Stopwatch from "./Stopwatch";
import {
  DeviceButton,
  PartnerButton,
  PowerButton,
  TimerText,
  TimerTypeText,
} from "./styled";

import moment from "moment";
import { BsFillPeopleFill } from "react-icons/bs";
import { setRemoteCall } from "../../remote";
import { getGlobalState, setStorageState, useGlobalState } from "../../store";
import { colors } from "../../theme";
import Joypad from "../../users/icons/Joypad";
import Mouse from "../../users/icons/Mouse";
import Tv from "../../users/icons/Tv";

function Timer() {
  const toast = useToast();
  const loggedIn = getGlobalState("loggedIn"); //dont remove
  const [timeLog, setTimeLog] = useGlobalState("timeLog"); //dont remove
  const [users] = useGlobalState("users");
  const [selectedUsers, setSelectedUsers] = useState([
    loggedIn ? loggedIn._id : "",
  ]);
  const [devices, setDevices] = useState([]);
  const [toggleUsers, setToggleUsers] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(null);

  //we need to find out today date
  //start counter
  const { seconds, minutes, hours, isRunning, start, pause, reset } =
    useStopwatch({
      autoStart: false,
      offsetTimestamp: currentOffset,
    });

  /**
   * toggle Timer
   */
  const toggleLog = () => {
    if (isRunning) {
      stopLog();
    } else {
      if (!devices || devices.length < 1) {
        return toast({
          title: "Please choose a device first",
          status: "warning",
          position: "top",
        });
        //we need devices
      }
      startLog();
    }
  };
  /**
   * start log data
   */
  const startLog = () => {
    let startData = {
      task: "training",
      devices: devices,
      start: new Date(),
      type: "log",
      status: 1,
      sharedTimeLog: null, //we will save individual time logs
      group: selectedUsers,
      user: loggedIn._id,
    };
    setRemoteCall("setTimeLog", startData, "Counter has started", (log) => {
      setStorageState("timeLog", log);
      setTimeLog(log);
      start();
      //if its a group session
      if (selectedUsers && selectedUsers.length >= 1) {
        //lets get he id for the active one
        const thisLog = log.find((l) => l.status === 1 && l.group);
        //if its a group session
        selectedUsers.map((user) => {
          //only update this user
          if (user === loggedIn._id) return null;
          //get this user
          const thisUser = users.find((u) => u._id === user);
          if (!thisUser) return null;
          //lets save one for each user and update data proprieties
          let thisUserData = {
            ...startData,
            user: user,
            sharedTimeLog: thisLog._id, //let set the group id
            devices:
              thisUser.devices &&
              thisUser.devices
                .map((de, i) => {
                  return { ...de, id: i };
                })
                .filter((d) => d.isDefault), //lets set users default device
          };
          //lets save the user data
          setRemoteCall("setTimeLog", thisUserData, null);
          return user;
        });
      } else {
      }
    });
  };
  /**
   * stop log data
   */
  const stopLog = () => {
    const activeLog = timeLog.find((log) => log.status === 1);
    let stopData = {
      ...activeLog,
      end: new Date(),
      status: 0,
    };
    setRemoteCall("setTimeLog", stopData, "Counter has stopped", (log) => {
      setStorageState("timeLog", log);
      setTimeLog(log);
      setSelectedUsers([]);
      pause();
    });
  };
  /**
   * Calculate day log time passed for time offset
   * @returns
   */
  const currentUsedTime = () => {
    let duration = 0;
    const today = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    timeLog &&
      timeLog.map((log) => {
        const start = moment(log.start);
        let end = moment();
        if (log.end) {
          end = moment(log.end);
        }
        //if date started day before we should count only since 00h
        if (start.isSame(today, "day")) {
          const durationLogFull = moment.duration(end.diff(start));
          const secondFull = durationLogFull.asSeconds();
          duration = duration + secondFull;
        } else {
          const durationLogDay = moment.duration(end.diff(today));
          const secondDay = durationLogDay.asSeconds();
          duration = duration + secondDay;
        }
        return log;
      });
    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + duration);
    return stopwatchOffset;
  };

  useEffect(() => {
    const stopwatchOffset = currentUsedTime();
    setCurrentOffset(stopwatchOffset);
    const isTimerActive =
      (timeLog &&
        timeLog.length > 0 &&
        timeLog.find((log) => log.status === 1)) ||
      false;

    if (isTimerActive) {
      setDevices(isTimerActive.devices);
      if (isTimerActive.group) {
        setSelectedUsers(isTimerActive.group);
      }
    } else {
      setDevices(
        loggedIn &&
        loggedIn.devices
          ? loggedIn.devices
              .map((de, i) => {
                return { ...de, id: i };
              })
              .filter((d) => d.isDefault)
          : []
      );
    }
    reset(stopwatchOffset, isTimerActive);
  }, [timeLog]);

  /** Render Output */
  return (
    <>
      <Box
        height={["400px", "400px", "auto", "auto", "auto"]}
        position={"relative"}
      >
        <Box
          // position={"absolute"}
          top={0}
          left={[
            0,
            "calc(50% - 200px)",
            "calc(50% - 200px)",
            "calc(50% - 200px)",
          ]}
        >
          <TimerText>
            <TimerTypeText>{moment().format("LLLL")}</TimerTypeText>
            <span>{hours < 10 ? `0${hours}` : hours}</span>:
            <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
            <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            {loggedIn.client && (
              <TimerTypeText>
                <Box
                  onClick={() => setToggleUsers(true)}
                  mt={".5em"}
                  textAlign={"center"}
                >
                  {((selectedUsers && selectedUsers.length >= 1) ||
                    !isRunning) && (
                    <PartnerButton>
                      <Flex>
                        <BsFillPeopleFill />{" "}
                        <Box ml={".4em"}>
                          {isRunning ? "View Players" : "Add a Player"}
                        </Box>
                      </Flex>
                    </PartnerButton>
                  )}
                </Box>
              </TimerTypeText>
            )}
          </TimerText>
          <Stopwatch
            size={400}
            totalTime={18000}
            currentSeconds={seconds}
            currentTime={hours * 3600 + minutes * 60 + seconds}
          />
          <Box textAlign={"center "}>
            <PowerButton
              active={isRunning ? "active" : "notActive"}
              onClick={() => toggleLog()}
            >
              <PowerIcon
                fill={colors.secondary[500] || "black"}
                width={"80"}
                height={"80"}
              />
            </PowerButton>
          </Box>
          <Flex
            mt={"1em"}
            textAlign={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={"wrap"}
          >
            {loggedIn &&
              loggedIn.devices &&
              loggedIn.devices.map((device, i) => {
                const isActive = devices.find((d) => d.id === i);
                if (!isActive && isRunning) return null;
                return (
                  <Box
                    key={i}
                    onClick={() => {
                      if (isRunning) return null;
                      if (!isActive) {
                        setDevices([...devices, { ...device, id: i }]);
                      } else {
                        setDevices(devices.filter((d) => d.id !== i));
                      }
                    }}
                  >
                    <DeviceButton active={isActive}>
                      {device.type === "computer" && (
                        <Mouse fill={"#fff"} height={30} width={25} />
                      )}
                      {device.type === "console" && (
                        <Joypad fill={"#fff"} height={35} width={30} />
                      )}
                      {device.type === "tv" && (
                        <Tv fill={"#fff"} height={30} width={25} />
                      )}
                    </DeviceButton>
                    <Box fontSize={".8em"} color={[colors.secondary[500]]}>
                      {device.name}
                    </Box>
                  </Box>
                );
              })}
          </Flex>
        </Box>
      </Box>
      {toggleUsers && (
        <ChooseUserModal
          setSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          isRunning={isRunning}
          setOpenModal={() => setToggleUsers(false)}
        />
      )}
    </>
  );
}

export default Timer;
