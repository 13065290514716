import { Box, Button, Flex, Link as LinkCk } from "@chakra-ui/react";
import React from "react";
import { BsFillBarChartFill, BsLink45Deg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getGlobalState, setStorageState, useGlobalState } from "../../store";
import { colors } from "../../theme";
import {
  ListContainer,
  StyledDrawer,
  StyledListItem,
  StyledListWrap,
  StyledLogo,
  StyledNavigationPanel,
} from "./styled";

import { Icon, Tooltip } from "@chakra-ui/react";
import { FaUserFriends } from "react-icons/fa";
import { RiSettings2Fill } from "react-icons/ri";
import { AiFillTrophy } from "react-icons/ai";
import { TbLogout } from "react-icons/tb";
import { FaNewspaper } from "react-icons/fa";

import { Calendar, Dashboard, User } from "./icons";

const Navigation = (props) => {
  // const [showApproval, setShowApproval] = useState(false)
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn");
  const [userDetails, setUserDetails] = useGlobalState("editProfile"); //dont remove
  const [teamDetails, setTeamDetails] = useGlobalState("editTeam"); //dont remove
  const socket = getGlobalState("socket");
  const [pageClass] = useGlobalState("pageClass");
  // get socket for status
  // const timeLog = getGlobalState('timeLog') //need to get feed
  const [feed, setCalendar] = useGlobalState("calendar"); //dont remove
  // const [requests, setRequests] = useGlobalState("requests"); //dont remove
  const users = getGlobalState("users");

  const RenderLinks = ({ clientId }) => {
    if (!clientId) return null;
    return (
      <Box
        position={"absolute"}
        zIndex={"300"}
        right={{ base: "50%", md: 0 }}
        mr={{ base: "-45px", md: 0 }}
        top={{ base: "110px", sm: "120px", md: "20px" }}
      >
        <Button
          borderRadius={"5px"}
          size={"sm"}
          textDecoration={"none"}
          colorScheme="secondary"
          _hover={{
            background: "primary.500",
          }}
        >
          <LinkCk
            href={`/register/${clientId}`}
            target={"_blank"}
            textDecoration={"none"}
            _hover={{ textDecoration: "none" }}
          >
            <Flex alignItems={"center"}>
              <Icon
                as={BsLink45Deg}
                color={"white"}
                width={"18px"}
                height={"18px"}
              />
              Invitation link
            </Flex>
          </LinkCk>
        </Button>
      </Box>
    );
  };

  const logout = async () => {
    setStorageState("loggedIn", null);
    setLoggedIn(null);
    window.location = "/";
    if (socket) await socket.close();
  };

  if (!loggedIn) return null;

  if (
    (loggedIn && !loggedIn.admin) ||
    (loggedIn && loggedIn.admin && loggedIn.role !== "global")
  ) {
    return (
      <>
        <LinkCk
          href={`/dashboard`}
          textDecoration={"none"}
          _hover={{ textDecoration: "none" }}
          className={"fadeInLeft"}
        >
          <StyledLogo />
        </LinkCk>
        <RenderLinks clientId={loggedIn.client} />
        <StyledNavigationPanel>
          <StyledDrawer variant="permanent" anchor="left">
            <StyledListWrap>
              {/* <ListContainer>
            </ListContainer> */}
              <ListContainer className="menuItems">
                <StyledListItem active={pageClass === "dashboard"}>
                  {/* <Tooltip label="Dashboard"> */}
                  <Link to={"/dashboard"}>
                    <Dashboard
                      fill={
                        pageClass === "dashboard"
                          ? "white" //colors.secondary[500]
                          : "white" //colors.primary[300]
                      }
                      width={"25px"}
                      height={"25px"}
                    />
                  </Link>
                  {/* </Tooltip> */}
                </StyledListItem>
                <StyledListItem active={pageClass === "feed"}>
                  {/* <Tooltip label="News Feed"> */}
                  <Link to={"/feed"}>
                    {feed && feed.length >= 1 && <i>{feed.length}</i>}
                    <Icon
                      as={FaNewspaper}
                      fill={
                        pageClass === "clients"
                          ? "white" //colors.secondary[500]
                          : "white" //colors.primary[300]
                      }
                      width={"28px"}
                      height={"28px"}
                    />
                  </Link>
                  {/* </Tooltip> */}
                </StyledListItem>
                <StyledListItem active={pageClass === "users"}>
                  {/* <Tooltip label="Ranks"> */}
                  <Link to={"/users"}>
                    <AiFillTrophy
                      fontSize={"1.8em"}
                      fill={
                        pageClass === "users"
                          ? "white" //colors.secondary[500]
                          : "white" //colors.primary[300]
                      }
                      width={"40px"}
                      height={"40px"}
                    />
                  </Link>
                  {/* </Tooltip> */}
                </StyledListItem>
                {loggedIn && loggedIn.admin && (
                  <StyledListItem active={pageClass === "reports"}>
                    {/* <Tooltip label="CO2 Log"> */}
                    <Link to={"/reports"}>
                      <Icon
                        as={BsFillBarChartFill}
                        fill={
                          pageClass === "reports"
                            ? "white" //colors.secondary[500]
                            : "white" //colors.primary[300]
                        }
                        width={"28px"}
                        height={"28px"}
                      />
                    </Link>
                    {/* </Tooltip> */}
                  </StyledListItem>
                )}
              </ListContainer>
              <ListContainer className="settings">
                <Box
                  m={"0 auto"}
                  mt={"20px"}
                  width={"1px"}
                  h={"20px"}
                  borderRight={{ md: `1px solid ${colors.primary[50]}` }}
                />
                <StyledListItem>
                  {/* <Tooltip label="Settings"> */}
                  <a onClick={() => !userDetails && setUserDetails(true)}>
                    <Icon
                      as={RiSettings2Fill}
                      color={"white"}
                      width={"28px"}
                      height={"28px"}
                    />
                  </a>
                  {/* </Tooltip> */}
                </StyledListItem>
                <StyledListItem active={pageClass === "team"}>
                  {/* <Tooltip label="Teams"> */}
                  <a onClick={() => !teamDetails && setTeamDetails(true)}>
                    <Icon
                      as={FaUserFriends}
                      fill={
                        pageClass === "team"
                          ? "white" //colors.secondary[500]
                          : "white" //colors.primary[300]
                      }
                      width={"28px"}
                      height={"28px"}
                    />
                  </a>
                  {/* </Tooltip> */}
                </StyledListItem>
                <StyledListItem>
                  {/* <Tooltip label="Logout"> */}
                  <a onClick={() => logout()}>
                    <Icon
                      as={TbLogout}
                      color={"white"}
                      width={"28px"}
                      height={"28px"}
                    />
                  </a>
                  {/* </Tooltip> */}
                </StyledListItem>
              </ListContainer>
            </StyledListWrap>
          </StyledDrawer>
        </StyledNavigationPanel>
      </>
    );
  }

  return (
    <>
      <StyledLogo />
      <RenderLinks clientId={loggedIn.client} />
      <StyledNavigationPanel>
        <StyledDrawer variant="permanent" anchor="left">
          <StyledListWrap>
            {/* <ListContainer>
          </ListContainer> */}
            <ListContainer className="menuItems">
              <StyledListItem active={pageClass === "dashboard"}>
                {/* <Tooltip label="Dashboard"> */}
                <Link to={"/dashboard"}>
                  <Dashboard
                    fill={
                      pageClass === "dashboard"
                        ? "white" //colors.secondary[500]
                        : "white" //colors.primary[300]
                    }
                    width={"25px"}
                    height={"25px"}
                  />
                </Link>
                {/* </Tooltip> */}
              </StyledListItem>
              <StyledListItem active={pageClass === "clients"}>
                {/* <Tooltip label="Teams"> */}
                <Link to={"/clients"}>
                  <Icon
                    as={FaUserFriends}
                    fill={
                      pageClass === "clients"
                        ? "white" //colors.secondary[500]
                        : "white" //colors.primary[300]
                    }
                    width={"28px"}
                    height={"28px"}
                  />
                </Link>
                {/* </Tooltip> */}
              </StyledListItem>
              <StyledListItem active={pageClass === "feed"}>
                {/* <Tooltip label="News Feed"> */}
                <Link to={"/feed"}>
                  {feed && feed.length >= 1 && <i>{feed.length}</i>}
                  <Icon
                    as={FaNewspaper}
                    fill={
                      pageClass === "clients"
                        ? "white" //colors.secondary[500]
                        : "white" //colors.primary[300]
                    }
                    width={"28px"}
                    height={"28px"}
                  />
                </Link>
                {/* </Tooltip> */}
              </StyledListItem>
              <StyledListItem active={pageClass === "users"}>
                {/* <Tooltip label="Ranks"> */}
                <Link to={"/users"} style={{ fontSize: "1.8em" }}>
                  <AiFillTrophy
                    fill={
                      pageClass === "users"
                        ? "white" //colors.secondary[500]
                        : "white" //colors.primary[300]
                    }
                    width={"40px"}
                    height={"40px"}
                  />
                </Link>
                {/* </Tooltip> */}
              </StyledListItem>
              {loggedIn && loggedIn.admin && (
                <StyledListItem active={pageClass === "reports"}>
                  {/* <Tooltip label="CO2 Log"> */}
                  <Link to={"/reports"}>
                    <Icon
                      as={BsFillBarChartFill}
                      fill={
                        pageClass === "reports"
                          ? "white" //colors.secondary[500]
                          : "white" //colors.primary[300]
                      }
                      width={"28px"}
                      height={"28px"}
                    />
                  </Link>
                  {/* </Tooltip> */}
                </StyledListItem>
              )}
            </ListContainer>
            <ListContainer className="settings">
              <Box
                m={"0 auto"}
                mt={"20px"}
                width={"1px"}
                h={"20px"}
                borderRight={{ md: `1px solid ${colors.primary[50]}` }}
              />
              <StyledListItem>
                {/* <Tooltip label="Settings"> */}
                <a onClick={() => !userDetails && setUserDetails(true)}>
                  <Icon
                    as={RiSettings2Fill}
                    color={"white"}
                    width={"28px"}
                    height={"28px"}
                  />
                </a>
                {/* </Tooltip> */}
              </StyledListItem>

              <StyledListItem>
                {/* <Tooltip label="Logout"> */}
                <a onClick={() => logout()}>
                  <Icon
                    as={TbLogout}
                    color={"white"}
                    width={"28px"}
                    height={"28px"}
                  />
                </a>
                {/* </Tooltip> */}
              </StyledListItem>
              {/* <StyledListItem active={pageClass === "requests"}>
                <Link to={"/requests"}>
                  {requests && requests.length >= 1 && <i>{requests.length}</i>}
                  <Requests
                    fill={pageClass === "requests" ? "white" : null}
                    width={"25"}
                    height={"25"}
                  />
                  <span>Requests</span>
                </Link>
              </StyledListItem> */}
            </ListContainer>
          </StyledListWrap>
        </StyledDrawer>
      </StyledNavigationPanel>
    </>
  );
};

export default Navigation;
