import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  Link,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../theme";

import { FaUserCircle } from "react-icons/fa";
import { Board } from "../components/styled";
import { PlayerValue } from "./styled";
import Upload from "../components/Upload";

import { setRemoteCall } from "../remote";
import { setStorageState, useGlobalState } from "../store";
// import ClientHistory from "./ClientHistory";

const tableCollumns = [
  { k: "_id", l: "_id" },
  { k: "image", l: "image", t: "upload" },
  // { k: "name", l: "Team Name" },
  { k: "status", l: "Status" },
  { k: "theme", l: "Theme" },
];

export default function Edit(props) {
  const toast = useToast();
  const [details, setDetails] = useState(props.data || {});
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn");
  const [clients] = useGlobalState("clients");
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value });
  };

  const getUsersForClient = async () => {
    if (
      (loggedIn.status === 1 && loggedIn.client) ||
      (loggedIn.admin && loggedIn.role === "global")
    ) {
      setRemoteCall("getClientUsers", { id: details?._id }, null, (data) => {
        if (!data) return null;
        setUsers(data);
      });
    }
  };

  useEffect(() => {
    if (loggedIn.client) {
      setDetails(clients.find((c) => c._id === loggedIn.client) || {});
    }
    return () => {};
  }, [loggedIn]);

  const logout = async () => {
    setStorageState("loggedIn", null);
    setLoggedIn(null);
    window.location = "/";
  };

  /**
   * Use effects
   */
  useEffect(() => {
    getUsersForClient();
    return () => {};
  }, [details?._id]);

  const leaveTeam = async () => {
    setIsOpen({
      title: "Team Leave",
      text: `Are you sure you want to leave the team - ${details.name}? you will need to relogin.`,
      cb: () => {
        setRemoteCall(
          "unsetClients",
          { id: details._id },
          "Leaving team",
          (data) => {
            if (data) {
              console.log(data);
              setDetails({ name: "", theme: { primary: null } });
              setLoggedIn({
                ...loggedIn,
                status: 1,
                theme: null,
                client: null,
              });
              setStorageState("loggedIn", {
                ...loggedIn,
                status: 1,
                theme: null,
                client: null,
              });
              props.setOpenModal();
              logout();
            } else {
              toast({
                title: "Error while leaving team",
              });
            }
          }
        );
        setIsOpen(false);
      },
    });
  };
  const createTeam = async () => {
    if (!details.name) return toast({ title: "Please enter a team name" });
    setIsOpen({
      title: "Team Join",
      text: `Are you sure you want to join the team - ${details.name}? you will need to relogin.`,
      cb: () => {
        setRemoteCall(
          "setClients",
          { ...details, status: 1 },
          "Joining team",
          (data) => {
            if (data) {
              console.log(data);
              setDetails(data.client || {});
              setLoggedIn({
                ...loggedIn,
                status: data.user.status,
                admin: data.user.admin,
                theme: data.client.theme,
                client: data.user.client,
              });
              setStorageState("loggedIn", {
                ...loggedIn,
                status: data.user.status,
                admin: data.user.admin,
                theme: data.client.theme,
                client: data.user.client,
              });
              props.setOpenModal();
              logout();
            } else {
              toast({
                title: "Error while joining team",
              });
            }
          }
        );
        setIsOpen(false);
      },
    });
  };
  const updateUserStatus = async (id, status) => {
    setIsOpen({
      title: "Update User Status",
      text: "Are you sure you want to update this user status?",
      cb: () => {
        setRemoteCall("updateUserStatus", { id, status }, null, (data) => {
          getUsersForClient();
          setIsOpen(false);
        });
      },
    });
  };

  const toggleAdmin = async (id, admin) => {
    setIsOpen({
      title: "Update admin status",
      text: admin
        ? "Are you sure you want to make the user an admin?"
        : "Are you sure you want to remove the user from admin",
      cb: () => {
        setRemoteCall("toggleUserAdmin", { id, admin }, null, (data) => {
          getUsersForClient();
          setIsOpen(false);
        });
      },
    });
  };

  const removeClient = async (id) => {
    setIsOpen({
      title: "Remove user from team",
      text: "Are you sure you want to remove the user from the team",
      cb: () => {
        setRemoteCall("removeUserClient", { id }, null, (data) => {
          getUsersForClient();
          setIsOpen(false);
        });
      },
    });
  };

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall("setClients", data, notice, (cb) => {
      setLoggedIn({
        ...loggedIn,
        theme: cb?.theme,
      });
    });
  };
  /**
   * PlayerCard component
   */
  const PlayerCard = ({ user, i }) => {
    return (
      <Board
        margin={"0 3% 3% 0"}
        width={"100%"}
        // active={i === 0}
      >
        <Flex
          alignContent={"center"}
          className={"content"}
          alignItems={"center"}
        >
          <Flex
            alignContent={"center"}
            className={"content"}
            alignItems={"center"}
            width={"75%"}
          >
            <Box
              display={["block", "block", "none", "block", "block"]}
              marginRight={"1em"}
            >
              {user.image ? (
                <Box
                  borderRadius={"50%"}
                  height={"4em"}
                  width={"4em"}
                  background={`url(${user.image})`}
                  backgroundPosition={"center"}
                  backgroundSize={"cover"}
                  // border={".3em solid white"}
                />
              ) : (
                <Icon
                  as={FaUserCircle}
                  color={colors.secondary[500]}
                  height={"4em"}
                  width={"4em"}
                />
              )}
            </Box>
            <Box>
              <PlayerValue>
                {user.firstName} {user.lastName}
                <span>{user.status ? "Active -" : "Inactive -"} </span>
              </PlayerValue>
            </Box>
          </Flex>
          {loggedIn && loggedIn.admin ? (
            <Box>
              <Button
                colorScheme="secondary"
                width={"100%"}
                borderRadius={"5px"}
                size={"xs"}
                ml={".4em"}
                mb={".4em"}
                _hover={{
                  background: "primary.500",
                }}
                onClick={() => removeClient(user._id)}
              >
                Remove
              </Button>
              {user.status !== 1 ? (
                <Button
                  colorScheme="secondary"
                  width={"100%"}
                  borderRadius={"5px"}
                  size={"xs"}
                  mb={".4em"}
                  ml={".4em"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => updateUserStatus(user._id, 1)}
                >
                  Approve
                </Button>
              ) : (
                ""
              )}
              {!user.admin ? (
                <Button
                  colorScheme="secondary"
                  width={"100%"}
                  borderRadius={"5px"}
                  size={"xs"}
                  mb={".4em"}
                  ml={".4em"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => toggleAdmin(user._id, true)}
                >
                  Promote
                </Button>
              ) : (
                ""
              )}
              {user.admin && users.length > 1 ? (
                <Button
                  colorScheme="secondary"
                  width={"100%"}
                  borderRadius={"5px"}
                  size={"xs"}
                  mb={".4em"}
                  ml={".4em"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => toggleAdmin(user._id, false)}
                >
                  Demote
                </Button>
              ) : (
                ""
              )}
            </Box>
          ) : (
            ""
          )}
        </Flex>
      </Board>
    );
  };

  /**
   * Render user cards
   */
  const RenderUsers = () => {
    return (
      <Box mt={"2em"}>
        <Heading fontSize={"1.4em"}>Teams users</Heading>

        {(!users || users.length === 0) && "no users"}
        {users &&
          users.length > 0 &&
          users.map((user, i) => <PlayerCard key={i} i={i} user={user} />)}
      </Box>
    );
  };
  const disabled =
    (loggedIn.client && details && details._id && loggedIn.status === 2) ||
    (loggedIn.client &&
      details &&
      details._id &&
      loggedIn.status === 1 &&
      !loggedIn.admin);

  console.log(loggedIn);

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          {loggedIn.client && details._id && loggedIn.status === 2
            ? "Waiting Team Approval"
            : ""}
          {!loggedIn.client && !details._id && loggedIn.status === 1
            ? "Create or join a team"
            : ""}
          {!loggedIn.client && details._id && loggedIn.status === 1
            ? `Team name - ${details.name}`
            : ""}
          {loggedIn.client && details._id && loggedIn.status === 1
            ? `Team ${details.name}`
            : ""}
        </DrawerHeader>

        <DrawerBody overflow={"auto"}>
          {loggedIn.client && details._id && loggedIn.status === 2 && (
            <Box
              m={"1em"}
              fontSize={"1.2em"}
              bg={"#fafafa"}
              border={"#ccc 1px dashed"}
              borderRadius={"8px"}
              padding={"1em"}
            >
              Your account needs approvel by a Team Admin. <br />
              You can leave a team at any time by using the button "Leave Team"
            </Box>
          )}{" "}
          <Box margin={"2em 0"}>
            {details.image && (
              <img
                style={{
                  width: "100%",
                  height: "20vh",
                  objectFit: "contain",
                }}
                src={details.image}
              />
            )}
            <Upload
              callback={(files) =>
                handleFormFieldChange("image", { target: { value: files } })
              }
            />
          </Box>
          <HStack>
            <Input
              size="lg"
              onChange={(e) => {
                handleFormFieldChange("name", e);
                //lets select the team by id
                const teamId = document.querySelector(
                  "#clients" + " option[value='" + e.target.value + "']"
                )?.dataset?.elemid;
                if (teamId) {
                  setDetails(clients.find((c) => c._id === teamId));
                } else {
                  setDetails({ name: e.target.value });
                }
              }}
              disabled={disabled}
              list="clients"
              type={"text"}
              width={"80%"}
              placeholder={"Team Name"}
              value={details["name"]}
            />
            <datalist id="clients">
              {clients &&
                clients.map((name, index) => (
                  <option
                    onSelect={(e) => {
                      console.log(e);
                    }}
                    key={name._id}
                    data-elemid={name._id}
                    value={name.name}
                  ></option>
                ))}
            </datalist>
            {((loggedIn.admin && loggedIn.role === "global") ||
              (loggedIn.admin && loggedIn.client) ||
              (loggedIn.client && details._id && loggedIn.status === 2) ||
              (!loggedIn.admin && !loggedIn.client && !details._id)) && (
              <Input
                size="lg"
                disabled={disabled}
                onChange={(event) =>
                  handleFormFieldChange("theme", {
                    target: {
                      value: {
                        ...(details["theme"] || {}),
                        primary: event.target.value,
                      },
                    },
                  })
                }
                type={"color"}
                width={"20%"}
                height={"3rem"}
                marginRight={".5em"}
                placeholder={"color"}
                value={details["theme"]?.primary}
              />
            )}
          </HStack>
          {loggedIn.role === "global" && loggedIn.admin && (
            <InputGroup margin={".5em 0"} size="sm">
              <Select
                height="3em"
                onChange={(event) => handleFormFieldChange("status", event)}
                placeholder="Selecionar estado"
                value={details["status"]}
              >
                <option value={0}> Inativo </option>
                <option value={1}> Ativo </option>
              </Select>
            </InputGroup>
          )}
          {((loggedIn.admin && loggedIn.role === "global") ||
            (loggedIn.status === 1 && loggedIn.client)) && (
            <Flex>
              <Button
                width={"100%"}
                borderRadius={"5px"}
                ml={".4em"}
                mb={".4em"}
                textDecoration={"none"}
              >
                <Link
                  href={`/register/${details._id}`}
                  target={"_blank"}
                  textDecoration={"none"}
                  _hover={{ textDecoration: "none" }}
                >
                  Team Register
                </Link>
              </Button>
              <Button
                width={"100%"}
                borderRadius={"5px"}
                ml={".4em"}
                mb={".4em"}
              >
                <Link
                  href={`/login/${details._id}`}
                  target={"_blank"}
                  textDecoration={"none"}
                  _hover={{ textDecoration: "none" }}
                >
                  Team Login
                </Link>
              </Button>
            </Flex>
          )}
          {!loggedIn.client && details._id && (
            <Box
              m={"1em"}
              fontSize={"1.2em"}
              bg={"#fafafa"}
              border={"#ccc 1px dashed"}
              borderRadius={"8px"}
              padding={"1em"}
            >
              Send request to the <strong>{details.name} </strong>Team Admin?{" "}
              <br />
              You can leave a team at any time by using the button "Leave Team"
            </Box>
          )}{" "}
          {!loggedIn.client && (
            <Button
              colorScheme="primary"
              onClick={() => createTeam()}
              w={"100%"}
            >
              {details._id ? "Join Team" : "Create Team"}
            </Button>
          )}
          {((loggedIn.status === 1 && loggedIn.client) ||
            (loggedIn.admin && loggedIn.role === "global")) && <RenderUsers />}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          {loggedIn.client && (
            <Button
              colorScheme="primary"
              variant={"outline"}
              onClick={() => leaveTeam()}
              mr={3}
            >
              Leave Team
            </Button>
          )}
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancel
          </Button>
          {props.setOpenModal && loggedIn.admin && (
            <Button
              colorScheme="primary"
              onClick={() => {
                if (!details.name)
                  return toast({ title: "Please enter a team name" });
                set({ ...details }, ` update client`, "clients");
                props.setOpenModal();
              }}
            >
              Save
            </Button>
          )}

          {!loggedIn.client && (
            <Button colorScheme="primary" onClick={() => createTeam()}>
              {details._id ? "Join Team" : "Create Team"}
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
      <AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent background={"white"}>
            <AlertDialogHeader fontSize="2xl" fontWeight="bold">
              {isOpen && isOpen.title}
            </AlertDialogHeader>

            <AlertDialogBody>{isOpen && isOpen.text}</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button onClick={() => isOpen && isOpen.cb()} ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Drawer>
  );
}
