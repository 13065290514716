// import { toast } from 'react-toastify'
import { createStandaloneToast } from "@chakra-ui/react";
import { io } from "socket.io-client";
import history from "./history";
import { getGlobalState, setGlobalState, setStorageState } from "./store";
import { getPresistData } from "./util";
export const setRemoteCall = (callType, data, message, cb) => {
  const { toast } = createStandaloneToast();
  const socket = getGlobalState("socket");
  if (!socket) {
    setTimeout(() => {
      setRemoteCall(callType, data, message, cb);
    }, 1000);
  } else {
    socket.emit(callType, data, (d) => cb && cb(d));
    if (message)
      toast({
        title: `${message}`,
        status: "success",
        position: "top",
      });
  }
};

export const socketListeners = (selfSocket) => {
  const socket = getGlobalState("socket");
  if (!socket) return null;

  ///get initial data
  socket.emit("getInitData");

  socket.on("exprired", (received) => {
    if (received) {
      console.log("User token has expired");
      setGlobalState("loggedIn", null);
      setStorageState("loggedIn", null);
      socket.close();
      return (window.location = "/");
    }
  });

  socket.on("clients", (received) => {
    setGlobalState("clients", received || []);
    // toast(`Got ${received.length} users`)
  });

  socket.on("calendar", (received) => {
    setGlobalState("calendar", received || []);
  });
  socket.on("offsets", (received) => {
    setGlobalState("offsets", received || []);
  });
  socket.on("users", (received) => {
    setGlobalState("users", received || []);
  });
  socket.on("points", (received) => {
    setGlobalState("points", received || 0);
  });
  socket.on("timeLog", (received) => {
    setGlobalState("timeLog", received || []);
    // toast(`Got ${received.length} users`)
  });
  socket.on("usersAvailable", (received) => {
    setGlobalState("availableUsers", received || []);
    // toast(`Got ${received.length} users`)
  });
  socket.on("userTypes", (received) => {
    setGlobalState("userTypes", received || []);
  });
  socket.on("requests", (received) => {
    setGlobalState("requests", received || []);
  });
  socket.on("userHistory", (received) => {
    setGlobalState("userHistory", received || []);
  });
};
export const checkAuthRoute = (redirectIfLoggedin) => {
  const loggedIn = getGlobalState("loggedIn");
  if (
    !loggedIn &&
    !getPresistData("g4pApp", "loggedIn") &&
    window?.location?.pathname !== "/"
  ) {
    return true;
    // return history.push("/");
  } else if (
    redirectIfLoggedin &&
    loggedIn &&
    getPresistData("g4pApp", "loggedIn")
  ) {
    return true;
    // return history.push("/dashboard");
  }
  return false;
};
export const checkAdminRoute = () => {
  const loggedIn = getGlobalState("loggedIn");
  if (
    (!loggedIn || !loggedIn.admin) &&
    !getPresistData("g4pApp", "loggedIn") &&
    window?.location?.pathname !== "/"
  ) {
    return true;
    // return (window.location = "/dashboard");
  } else if (!loggedIn || !loggedIn.admin) {
    return true;
    // return (window.location = "/dashboard");
  }
  return false;
};

export const userLoggedIn = async () => {
  const loggedIn = getGlobalState("loggedIn");
  if (!loggedIn) return null;

  try {
    const selfSocket = await io.connect("https://g4papi.corsdigital.com", {
      upgrade: true,
      query: `token=${loggedIn.token}`,
      extraHeaders: { Authorization: `Bearer ${loggedIn.token}` },
      // transports: ['websocket']
      // withCredentials: true,
      // upgrade: false,
      // query: `token=${loggedIn.token}`,
      // extraHeaders: { Authorization: `Bearer ${loggedIn.token}` },
      // transportOptions: {
      //   polling: {
      //     extraHeaders: {
      //       Authorization: `Bearer ${loggedIn.token}`
      //     }
      //   }
      // }
    });

    selfSocket.on("unauthorized", async (error, callback) => {
      if (
        error.data.type === "UnauthorizedError" ||
        error.data.code === "invalid_token"
      ) {
        setGlobalState("connectionError", true);
      }
    });

    selfSocket.on("connect_error", async (err) => {
      setGlobalState("connectionError", true);
    });
    selfSocket.on("connect", function (client) {
      if (getGlobalState("connectionError")) {
        setGlobalState("connectionError", false);
      }
    });
    //double cath when not working
    selfSocket.on("error", async (err) => {
      if (
        err.code === "invalid_token" &&
        err.type === "UnauthorizedError" &&
        err.message === "jwt expired"
      ) {
        // Handle token expiration
        console.log("User token has expired");
        setGlobalState("connectionError", true);
        // setStorageState("loggedIn", null);
        // setGlobalState("loggedIn", null);
        // await selfSocket.close();
        // return (window.location = "/");
      }
    });

    setGlobalState("socket", selfSocket);
    socketListeners();
  } catch (error) {
    console.log(error);
  }
};
