import * as React from 'react'

function Edit(props) {
  return (
    <svg
      width={36}
      height={29}
      viewBox="0 0 36 29"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'filter'}</title>
      <path
        d="M4.57 0C2.054 0 0 1.989 0 4.426c0 2.437 2.052 4.42 4.57 4.42 2.535 0 4.57-1.98 4.57-4.42C9.14 1.989 7.088 0 4.57 0zm0 1.729c1.527 0 2.783 1.217 2.783 2.695 0 1.478-1.26 2.691-2.783 2.691-1.524 0-2.779-1.215-2.779-2.691 0-1.477 1.25-2.695 2.78-2.695zm10.724 1.838c-.512 0-.905.378-.905.871s.393.872.905.872h19.8c.513 0 .906-.38.906-.872 0-.493-.393-.871-.906-.871h-19.8zm16.136 6.547c-2.535 0-4.57 1.985-4.57 4.426 0 2.437 2.051 4.42 4.57 4.42 2.518 0 4.57-1.983 4.57-4.42s-2.054-4.426-4.57-4.426zm0 1.73c1.526 0 2.779 1.22 2.779 2.694 0 1.482-1.266 2.692-2.78 2.692-1.524 0-2.782-1.212-2.782-2.692 0-1.48 1.256-2.695 2.783-2.695zM.906 13.663c-.513 0-.906.378-.906.87 0 .493.393.872.906.872H20.46c.512 0 .9-.377.9-.871 0-.495-.39-.872-.9-.872H.906zm3.664 6.49c-2.535 0-4.57 1.98-4.57 4.42C0 27.011 2.054 29 4.57 29c2.517 0 4.57-1.989 4.57-4.426 0-2.44-2.031-4.42-4.57-4.42zm0 1.729c1.521 0 2.776 1.21 2.78 2.686.016 1.482-1.25 2.7-2.78 2.7-1.526 0-2.779-1.22-2.779-2.695 0-1.474 1.255-2.691 2.78-2.691zm10.724 1.82c-.512 0-.905.379-.905.871 0 .493.396.877.905.877h19.8c.51 0 .906-.384.906-.877 0-.492-.393-.871-.906-.871h-19.8z"
        fill="#FFF"
        fillRule="nonzero"
        opacity={0.7}
      />
    </svg>
  )
}

export default Edit
