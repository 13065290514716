import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  useToast,
  InputRightElement,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillClockCircle, AiOutlineClockCircle } from "react-icons/ai";
import Header from "../components/header";
import { Calendar as CalendarIcon } from "../components/navigation/icons";
import { Board } from "../components/styled";
import { setRemoteCall } from "../remote";
import { getGlobalState, setGlobalState, useGlobalState } from "../store";
import { colors } from "../theme";
import Edit from "./Edit";
import { DayValue, PlayerValue, RowDashPage } from "./styled";

/**
 *   
 * name: String,
  status: Number,
  details: String,
  color: String,
  date: Date,
  history: Object,
  user: String,
 */

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: "_id", l: "_id" },
  { k: "image", l: "image", t: "upload" },
  { k: "name", l: "Title" },
  { k: "details", l: "Details", t: "textarea" },
  { k: "date", l: "date", t: "date" },
];

function Calendar(props) {
  const toast = useToast();
  // const [data, setData] = useState(false)
  const loggedIn = getGlobalState("loggedIn");
  let startDate = moment()
    .subtract(1, "year")
    .startOf("year")
    .format("YYYY-MM-DD");
  const [openModal, setOpenModal] = useState(false);

  let endDate = moment()
    // .endOf("week")
    .add(1, "day")
    .format("YYYY-MM-DD");
  const [dateStart, setDateStart] = useState(startDate);
  const [dateEnd, setDateEnd] = useState(endDate);
  const [messageText, setMessageText] = useState("");

  const [data, setData] = useGlobalState("calendar"); //dont remove
  // const users = getGlobalState('users')
  setGlobalState("pageClass", "feed");

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  };
  /**
   * get table data
   */
  const getRequests = useCallback(() => {
    setRemoteCall(
      "getCalendar",
      {
        start: dateStart,
        end: dateEnd,
        country: loggedIn.country,
        admin: loggedIn.admin,
      },
      "",
      (cb) => setData(cb)
    );
  }, [dateStart, dateEnd, setData]);

  useEffect(() => {
    getRequests();
  }, [dateStart, dateEnd]);

  // useEffect(() => {
  //   if (id && data && data.length > 0 && !openModal) {
  //     const foundRequest = data.find(d => d._id === id)
  //     setOpenModal(foundRequest)
  //   }
  // }, [id, data])
  let currentMonth = "";

  /**
   * Set table data
   */
  // const set = (data, notice) => {
  //   setRemoteCall("setRequest", data, notice, cb => {
  //     getRequests(cb);
  //   });
  // };
  // /**
  //  * Del table data
  //  */
  // const del = (data, notice) => {
  //   setRemoteCall("delRequest", data, notice, cb => {
  //     getRequests(cb);
  //   });
  // };
  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall("setNews", data, notice, (cb) => {
      getRequests(cb);
    });
  };
  /**
   * Del table data
   */
  const del = (data, notice) => {
    setRemoteCall("delNews", data, notice, (cb) => {
      getRequests(cb);
    });
  };

  const sendNotification = () => {
    if (!messageText || messageText.length < 1)
      return toast({
        title: "Error",
        description: "Please enter a message",
      });
    setRemoteCall(
      "sendNotification",
      { description: messageText },
      "message sent",
      (cb) => {
        setMessageText("");
      }
    );
  };

  /**
   * function that takes a string detects if there is a link and returns the string and the link inside a a tag
   * @param {*} link
   */
  const parseLink = (link) => {
    if (link) {
      if (link.includes("http")) {
        //preg match for link
        const links = link.match(/(http|https):\/\/[^\s]+/g);
        //replace all links with a tags
        links.forEach((l) => {
          link = link.replace(
            l,
            `<a href="${l}" target="_blank" rel="noopener noreferrer">${l}</a>`
          );
        });

        return link;
      } else {
        return link;
      }
    }
  };

  return (
    <div className={"users dashboard"}>
      <Header image={"/images/background.jpg"} height={"300px"}>
        <Flex
          alignContent={"space-between"}
          alignItems={"center"}
          flexDirection={["column", "column", "column", "row", "row"]}
          width={"100%"}
        >
          <Box
            width={{ base: "100%", md: "50%" }}
            mb={["1em", "1em", "1em", "0", "0"]}
            className={"headerText"}
          >
            <Heading as={"h1"} size="2xl" color={colors.primary[500]}>
              News Feed
            </Heading>
            <Heading as={"h3"} size="md" color={colors.secondary[500]}>
              News Calendar view
            </Heading>
          </Box>
          <Box width={"70%"}>
            {loggedIn && loggedIn.admin && loggedIn.role == "global" ? (
              <Flex>
                <Box width={"50%"}>
                  <InputGroup size="lg">
                    <Input
                      type={"text"}
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                      placeholder="Send a message"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        size="sm"
                        colorScheme="primary"
                        marginTop={".7rem"}
                        onClick={sendNotification}
                      >
                        send
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>
                <Button
                  width={"40%"}
                  colorScheme="secondary"
                  value="Submit!"
                  ml={[0, ".4em"]}
                  mb={["1em", "0", "0", "0", "0"]}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  <CalendarIcon
                    fill={"white"}
                    height={"1em"}
                    width={"1em"}
                    mr={".5em"}
                    style={{ marginRight: ".5em" }}
                  />
                  Add News
                </Button>
              </Flex>
            ) : (
              ""
            )}
          </Box>
        </Flex>
      </Header>
      <Box className={"wrapper"}>
        <Flex
          alignContent={"center"}
          justifyContent={"center"}
          width={{ base: "80%", md: "60%" }}
          m={"0 auto"}
          mt={"-1em"}
          flexDirection={["column", "column", "row", "row", "row"]}
        >
          <Input
            marginRight={"1em"}
            mt={0}
            width={["100%"]}
            type="date"
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
          />
          <Input
            type="date"
            width={["100%"]}
            mt={0}
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
          />
        </Flex>
        <RowDashPage
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          {data &&
            data.map((request, i) => {
              // let thisUser
              // if (users) {
              //   thisUser = users.find(c => c._id === request.user)
              // }
              let start = null;
              let end = null;
              if (request.created_at) {
                start = moment(request.created_at);
              } else if (request.start && request.start.date) {
                start = moment(request.start.date);
                end = moment(request.end.date);
              } else if (request.date) {
                start = moment(request.date);
              } else {
                start = moment(request.start.dateTime);
                end = moment(request.end.dateTime);
              }
              let link = null;
              if (request.type === "google") {
                link = request.htmlLink;
              } else if (request.type === "tweet") {
                link = `https://twitter.com/BetclicApogee/status/${request.id}`;
              }

              const thisMonth = start.format("MMMM");
              let displayMonth = false;
              if (currentMonth !== thisMonth) {
                currentMonth = thisMonth;
                displayMonth = true;
              }
              return (
                <>
                  {displayMonth && (
                    <Box
                      alignSelf={"stretch"}
                      width={"100%"}
                      margin={"2em 0"}
                      textAlign={"center"}
                    >
                      <Box
                        height="5em"
                        display={"inline-block"}
                        width={"1px"}
                        borderRight={`1px dashed ${colors.secondary[500]}`}
                        // background={colors.secondary[500]}
                      ></Box>
                      <Box
                        color={colors.secondary[500]}
                        fontSize={"2em"}
                        lineHeight={"0"}
                        mb={".5em"}
                      >
                        ●
                      </Box>
                      <Heading>{thisMonth}</Heading>
                    </Box>
                  )}
                  <Board
                    key={i}
                    margin={"0 3% 3% 0"}
                    as={motion.div}
                    variants={item}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    // active={i === categoryId}
                  >
                    <a
                      href={link}
                      target={"_blank"}
                      onClick={(e) => {
                        if (
                          request.type !== "google" &&
                          request.type !== "tweet" &&
                          loggedIn &&
                          loggedIn.admin
                        ) {
                          e.preventDefault();
                          setOpenModal(request);
                        }
                      }}
                    >
                      {request && (request.image || request.file) && (
                        <Box
                          height={"15vh"}
                          margin={"-1em -1em 1em -1em"}
                          background={`url(${request.image || request.file})`}
                          backgroundPosition={"center"}
                          backgroundSize={"cover"}
                          border={".3em solid white"}
                          borderRadius={"1em 1em 0 0"}
                        />
                      )}
                      <Flex
                        alignContent={"center"}
                        className={"content"}
                        alignItems={"start"}
                      >
                        <Box marginRight={"1em"} width={"40px"}>
                          <DayValue>
                            {/* {daysPassed} */}
                            {start.date()}
                            <span>
                              {moment(start.month() + 1, "M").format("MMM")}{" "}
                            </span>
                          </DayValue>
                        </Box>
                        <Box>
                          <PlayerValue>
                            {request.summary || request.name}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: parseLink(
                                  request.details || request.text
                                ),
                              }}
                            ></span>
                            {!request.type ||
                              (request.type !== "news" && (
                                <>
                                  <Flex mt={".5em"} alignItems={"center"}>
                                    <Box mr={".3em"}>
                                      <AiFillClockCircle size={15} />
                                    </Box>
                                    <span style={{ fontSize: "1.3em" }}>
                                      {moment(start).format("HH:mm")}
                                    </span>
                                  </Flex>
                                  {end && (
                                    <Flex>
                                      <Box mr={".3em"}>
                                        <AiOutlineClockCircle size={15} />
                                      </Box>
                                      <span>{moment(end).format("HH:mm")}</span>
                                    </Flex>
                                  )}
                                </>
                              ))}
                          </PlayerValue>
                        </Box>
                      </Flex>
                    </a>
                  </Board>
                </>
              );
            })}
        </RowDashPage>
      </Box>

      {openModal && (
        <Edit
          data={openModal}
          form={tableCollumns}
          set={set}
          del={del}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
}
export default Calendar;
