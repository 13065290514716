import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGlobalState } from "../store";
import RenderModal from "./Modals";

import { checkAdminRoute, checkAuthRoute, userLoggedIn } from "../remote";

import Calendar from "../Calendar/Calendar";
import Clients from "../Clients/Clients";
import Page from "../components/page";
import Dashboard from "../dashboard/Dashboard";
import DashboardAdmin from "../dashboard/DashboardAdmin";
import Login from "../login/Login";
import Register from "../login/Register";
import Reset from "../login/Reset";
import News from "../News/News";
import { getNewOver, over } from "../theme";
import Users from "../users/Users";
import "./App.css";
// import Requests from "../Requests/Requests";
import PWAPrompt from "react-ios-pwa-prompt";
import WatchAuth from "../auth/WatchAuth";
import history from "../history.js";
import Reports from "../Reports/Reports";
import ConnectionModal from "../components/Modal/ConnectionModal";

const App = props => {
  //we need this to rerender the app upon login
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn"); //dont remove  // const loggedIn = getGlobalState('loggedIn')
  const [darkMode, setDarkMode] = useGlobalState("darkMode"); //dont remove  // updating dakmode
  userLoggedIn();
  // useEffect(() => setOver(getNewOver(darkMode)), [darkMode])

  return (
    <ChakraProvider
      theme={getNewOver(darkMode, loggedIn?.theme?.primary) || over}
    >
      <Router history={history}>
        <WatchAuth>
          <Page>
            <Switch>
              <Route
                exact
                path="/dashboard"
                component={() => {
                  if (
                    loggedIn &&
                    loggedIn.admin &&
                    loggedIn.role === "global"
                  ) {
                    return checkAdminRoute() ? (
                      <Redirect to={"/dashboard"} />
                    ) : (
                      <DashboardAdmin />
                    );
                  } else {
                    return checkAuthRoute() ? (
                      <Redirect to={"/login"} />
                    ) : (
                      <Dashboard />
                    );
                  }
                }}
              />
              <Route
                exact
                path="/reset/:token"
                component={() => (
                  <Reset
                  // setStorageState={this.setStorageState}
                  />
                )}
              />

              <Route
                exact
                path="/users"
                component={() =>
                  checkAuthRoute() ? <Redirect to={"/login"} /> : <Users />
                }
              />
              <Route
                exact
                path="/feed"
                component={() =>
                  checkAuthRoute() ? <Redirect to={"/login"} /> : <Calendar />
                }
              />
              <Route
                exact
                path="/clients"
                component={() =>
                  checkAdminRoute() ? (
                    <Redirect to={"/dashboard"} />
                  ) : (
                    <Clients />
                  )
                }
              />

              <Route
                exact
                path="/news"
                component={() =>
                  checkAdminRoute() ? <Redirect to={"/dashboard"} /> : <News />
                }
              />

              {/* <Route
                exact
                path="/requests/:id?"
                component={() => checkAuthRoute( ) && <Requests />}
              /> */}
              <Route
                exact
                path="/reports"
                component={() =>
                  checkAdminRoute() ? (
                    <Redirect to={"/dashboard"} />
                  ) : (
                    <Reports />
                  )
                }
              />

              <Route
                exact
                path="/register/:teamId?"
                component={() => <Register />}
              />
              <Route exact path="/login/:teamId?" component={() => <Login />} />
              <Route
                exact
                path="/"
                component={() =>
                  checkAuthRoute(true) ? (
                    <Redirect to={"/dashboard"} />
                  ) : (
                    <Login />
                  )
                }
              />
            </Switch>
          </Page>
        </WatchAuth>
      </Router>
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        closeOnClick
      />
      <ConnectionModal />
      <PWAPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
      />
      <RenderModal />
    </ChakraProvider>
  );
};

export default App;
