import { Box, Flex } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaSmog } from "react-icons/fa";
import co2 from "../../co2";
import Results from "../../components/Status/Results";
import { Board } from "../../components/styled";
import { setRemoteCall } from "../../remote";
import { MetricValueWhite, PlayerValue } from "../styled";

import { ImPowerCord } from "react-icons/im";
import { RiGamepadLine } from "react-icons/ri";
import { SiGumtree } from "react-icons/si";

const RenderView = ({ details }) => {
  const [monthTimelog, setMonthTimelog] = useState(null);
  const [userPoints, setUserPoints] = useState(null);

  const getPlayerTimeLog = newSearch => {
    setRemoteCall(
      "getMonthTimeLog",
      {
        start: moment().startOf("year"),
        end: moment(),
        user: details._id
      },
      "",
      cb => {
        setMonthTimelog(calcData(cb));
      }
    );
  };

  const getPlayerPoints = newSearch => {
    setRemoteCall(
      "getPoints",
      {
        user: details._id
      },
      "",
      cb => {
        setUserPoints(cb);
      }
    );
  };

  useEffect(() => {
    getPlayerTimeLog();
    getPlayerPoints();
  }, []);

  const calcData = timelog => {
    let outputDataperDevice = [];
    let total = { hours: 0, power: 0, co2: 0, trees: 0, planned: 0 };
    timelog.map(log => {
      const start = moment(log.start);
      let plannedLog = 0;
      let end = moment();
      if (log.end) {
        end = moment(log.end);
      }
      const durationLogFull = moment.duration(end.diff(start));
      const secondFull = durationLogFull.asHours();

      //lets aggregate planned
      if (log.attr && log.attr.planned) {
        plannedLog = Number(log.attr.planned) / 60;
        plannedLog = secondFull - plannedLog;
      }
      total.hours += Number(secondFull);

      //lets aggregate device
      if (log.devices && log.devices.length > 0) {
        log.devices.map(device => {
          let thisPower = device.power;
          let thisCo2 = 0;

          const thisCountryData = co2.data.find(
            c => details.country === c.name
          );

          switch (device.type) {
            default:
              thisPower = (Number(device.power) || 0) * (secondFull || 0);
              thisCo2 +=
                thisPower *
                (thisCountryData?.value / 1000 || 0.31552).toFixed(2);

              if (!outputDataperDevice[device.name])
                outputDataperDevice[device.name] = {
                  hours: 0,
                  power: 0,
                  trees: 0,
                  co2: 0
                };
              total.planned += Number(plannedLog);
              total.power += thisPower;
              total.co2 += Number(thisCo2) || 0;
              total.trees += thisCo2 * 0.00714;
              outputDataperDevice[device.name].hours += Number(secondFull);
              outputDataperDevice[device.name].power += thisPower;
              outputDataperDevice[device.name].co2 += Number(thisCo2);
              outputDataperDevice[device.name].trees += thisCo2 * 0.00714;

              break;
          }
          return device;
        });
      }
      return log;
    });
    return { outputDataperDevice, total };
  };

  const RenderStats = () => {
    return (
      <Flex
        width={"100%"}
        justifyContent={"space-around"}
        flexDirection={["row", "row", "row", "row", "row"]}
        flexWrap={"wrap"}
      >
        <Box width={["45%", "100%", "100%", "100%", "100%"]}>
          <Board active={true} width={"100%"} margin={"0 0 0.5em 0"}>
            <Flex alignItems={"center"}>
              <RiGamepadLine color={"#fff"} size={25} />
              <Box ml={".5em"} fontSize={"1.1em"}>
                <MetricValueWhite>
                  {monthTimelog && monthTimelog?.total?.hours.toFixed(2)}{" "}
                  <span>Hours</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        {/* <Box width={["45%", "100%", "100%", "100%", "100%"]}>
          <Board active={true} width={"100%"} margin={"0 0 0.5em 0"}>
            <Flex alignItems={"center"}>
              <BsCalendar4Week color={"#fff"} size={25} />
              <Box ml={".5em"} fontSize={"1.1em"}>
                <MetricValueWhite>
                  {monthTimelog && monthTimelog?.total?.planned.toFixed(2)}{" "}
                  <span>Hours</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box> */}
        <Box width={["45%", "100%", "100%", "100%", "100%"]}>
          <Board active={true} width={"100%"} margin={"0 0 0.5em 0"}>
            <Flex alignItems={"center"}>
              <ImPowerCord color={"#fff"} size={25} />
              <Box ml={".5em"} fontSize={"1.1em"}>
                <MetricValueWhite>
                  {monthTimelog && monthTimelog?.total?.power.toFixed(2)}{" "}
                  <span>Kw/h</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        <Box width={["45%", "100%", "100%", "100%", "100%"]}>
          <Board
            dark={true}
            active={true}
            width={"100%"}
            margin={"0 0 0.5em 0"}
          >
            <Flex alignItems={"center"}>
              <FaSmog color={"#fff"} size={25} />
              <Box ml={".5em"} fontSize={"1.1em"}>
                <MetricValueWhite>
                  {monthTimelog && monthTimelog?.total?.co2.toFixed(2)}{" "}
                  <span>Kg CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        <Box width={["45%", "100%", "100%", "100%", "100%"]}>
          <Board
            dark={true}
            active={true}
            width={"100%"}
            margin={"0 0 0.5em 0"}
          >
            <Flex alignItems={"center"}>
              <SiGumtree color={"#fff"} size={25} />
              <Box ml={".5em"} fontSize={"1.1em"}>
                <MetricValueWhite>
                  {monthTimelog && monthTimelog?.total?.trees.toFixed(2)}{" "}
                  <span>Trees</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
      </Flex>
    );
  };
  return (
    <Box width={["100%"]}>
      <Box marginTop={"-10vh"} width={["90%"]} ml={"10%"}>
        {userPoints && <Results points={userPoints} />}
      </Box>
      <Flex
        width={"100%"}
        mt={"5%"}
        justifyContent={"space-between"}
        flexDir={["column", "row", "row", "row", "row"]}
      >
        <Box width={["100%", "50%", "50%", "50%", "50%"]}>
          <Board width={100} margin={"0"}>
            {/* <PlayerValue>
              {details.email}
              <span>email</span>
            </PlayerValue> */}
            {/* {details.phone && (
              <PlayerValue>
                {details.phone}
                <span>phone</span>
              </PlayerValue>
            )} */}
            {details.country && (
              <PlayerValue>
                {details.country}
                <span>country</span>
              </PlayerValue>
            )}
            {details.city && (
              <PlayerValue>
                {details.city}
                <span>city</span>
              </PlayerValue>
            )}
            {details.age && (
              <PlayerValue>
                {details.age}
                <span>age</span>
              </PlayerValue>
            )}
          </Board>
        </Box>
        <Box width={["100%", "45%", "45%", "45%", "45%"]}>
          <RenderStats />
        </Box>
      </Flex>
    </Box>
  );
};
export default RenderView;
