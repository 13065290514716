import { Box, Button, Flex, Heading, Icon } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";
import Header from "../components/header";
import { Board } from "../components/styled";
import { setRemoteCall } from "../remote";
import { colors } from "../theme";

import { MdGroupWork } from "react-icons/md";
import { setGlobalState, useGlobalState } from "../store";
import Edit from "./Edit";
import { ClientDetails, RowDashPage } from "./styled";

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: "_id", l: "_id" },
  // { k: "logo", l: "Logo", t: "upload" },
  { k: "name", l: "Company Name" },
  // { k: "date", l: "Start Data", t: "date" },
  { k: "status", l: "Status" },
  { k: "theme", l: "Theme" },
  // { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
];

export default function Clients(props) {
  const [clients, setClients] = useGlobalState("clients");

  const [openModal, setOpenModal] = useState(false);
  setGlobalState("pageClass", "clients");
  const options = {
    filterType: "dropdown",
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  };

  // const clients = ['cliente1', 'client2', 'client3', 'client4']

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall("setClients", data, notice);
  };

  /**
   * Use effects
   */

  return (
    <div className={"clients dashboard"}>
      <Header image={"/images/ranks.jpg"} height={"300px"}>
        <Flex alignContent={"space-between"} width={"100%"}>
          <Box width={{ base: "100%", md: "80%" }} className={"headerText"}>
            <Heading as={"h1"} size="2xl" color={colors.primary[500]}>
              Teams
            </Heading>
            <Heading as={"h3"} size="md" color={colors.secondary[500]}>
              teams list
            </Heading>
          </Box>
          <Box>
            <Button
              colorScheme="secondary"
              width={"100%"}
              ml={".4em"}
              _hover={{
                background: "primary.500",
              }}
              onClick={() => setOpenModal(true)}
            >
              Add a new Team
            </Button>
          </Box>
        </Flex>
      </Header>
      <div className={"wrapper"}>
        <RowDashPage
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          {clients &&
            clients.map((client, i) => {
              return (
                <Board
                  as={motion.div}
                  variants={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setOpenModal(client)}
                  // active={i === categoryId}
                  cursor={"pointer"}
                >
                  <Flex
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      {client.image ? (
                        <Box
                          borderRadius={"50%"}
                          height={"5em"}
                          width={"5em"}
                          background={`url(${client.image})`}
                          backgroundPosition={"center"}
                          backgroundSize={"cover"}
                          // border={".3em solid white"}
                        />
                      ) : (
                        <Icon
                          as={MdGroupWork}
                          color={
                            client?.theme?.primary
                              ? client.theme.primary
                              : colors.secondary[500]
                          }
                          width={["5em", "5em", "100%", "100%", "100%"]}
                          height={["3em", "3em", "3em", "3em", "3em"]}
                        />
                      )}
                    </Box>
                    <Box width={"80%"} textAlign={"left"}>
                      <ClientDetails>
                        <Flex
                          fontSize={"1.4em"}
                          justifyContent={"space-between"}
                        >
                          <strong>{client.name} </strong>{" "}
                        </Flex>
                        <Box fontSize={".8em"}>
                          {/* <span>Nível:</span> {client.level || 0}{" "} */}
                          {/* <span>Product:</span> {thisSectors || 'NA'}{' '} */}
                          <span>Users:</span> {client?.users?.length || 0}
                        </Box>
                      </ClientDetails>

                      <Flex flexWrap={"wrap"}>
                        {/* <ClientMetrics key={i}>
                          <Icon as={GoChecklist} color={colors.primary[300]} />{' '}
                          {getTotalScore(thisAssessment)}%
                        </ClientMetrics> */}
                        {/* <Link to={`/questions/${client._id}`}>
                          <Button
                            colorScheme={'primary'}
                            size="sm"
                            fontSize={'.7em'}
                            marginRight={'.2em'}
                            value="Submit!"
                            _hover={{
                              background: 'primary.500'
                            }}
                          >
                            <FiEdit3 />
                          </Button>
                        </Link> */}

                        {/* {thisSectors.map((product, i) => {
                          return (
                            <Link
                              key={i}
                              to={`/assessment/${product._id}/${client._id}`}
                            >
                              <Button
                                colorScheme={
                                  activeAssessment ? 'secondary' : 'primary'
                                }
                                size="sm"
                                marginRight={'.2em'}
                                fontSize={'.7em'}
                                value="Submit!"
                                _hover={{
                                  background: 'secondary.500'
                                }}
                              >
                                {activeAssessment ? (
                                  <Icon
                                    as={VscRepoPush}
                                    color={'white'}
                                    height={'1.5em'}
                                    width={'1.5em'}
                                    marginRight={'.2em'}
                                  />
                                ) : (
                                  <Icon
                                    as={VscRepo}
                                    color={'white'}
                                    height={'1.5em'}
                                    width={'1.5em'}
                                    marginRight={'.2em'}
                                  />
                                )}{' '}
                                {product.name}
                              </Button>
                            </Link>
                          )
                        })} */}
                      </Flex>
                    </Box>
                  </Flex>
                </Board>
              );
            })}
        </RowDashPage>
      </div>
      {openModal && (
        <Edit
          data={openModal}
          form={tableCollumns}
          set={set}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
}
