import styled, { css } from "styled-components";
import { colors } from "../../theme";
export const HeaderContainerImgWrap = styled.div`
  && {
    height: ${(props) => `${props.height}`};
    min-height: ${(props) => `${props.minHeight}`};
  }
`;

/**
 *
 background: radial-gradient(
                circle at center left,
                rgba(15, 76, 58, 0.9) 30%,
                rgba(15, 76, 58, 0.7) 100%
              ),
              url(${props.image});
 *
 */
export const HeaderContainerImg = styled.div`
  && {
    height: ${(props) => `${props.height}`};
    min-height: ${(props) => `${props.minHeight}`};
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    ${(props) =>
      props.image
        ? css`
            background: radial-gradient(
                circle at center left,
                ${colors.secondary.transparent[90]} 30%,
                ${colors.secondary.transparent[70]} 100%
              ),
              url(${props.image});
            background-size: auto, cover;
            background-repeat: no-repeat, no-repeat;
          `
        : css`
            background-color: ${colors.primary[500]};
          `};
  }
`;
export const HeaderContainer = styled.div`
  && {
    background: linear-gradient(
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0.7) 100%
      ),
      url("images/pattern.svg") no-repeat;
    background-size: auto, cover;
    backdrop-filter: saturate(100%) blur(15px);

    box-shadow: rgb(0 0 0 / 3%) 0px 3.5px 5.5px;
    /* min-height: ${(props) =>
      `${props.height ? `${props.height}vh` : "150px"}`}; */
    max-width: 1240px;
    border-radius: 55px;
    margin: -110px auto 0 auto;
    /* margin: 10vh auto; */
    width: 105%;
    @supports not (-webkit-touch-callout: none) {
      transform: perspective(10vw) rotateX(-1deg) rotateY(2deg);
      @media screen and (max-width: 768px) {
        transform: perspective(14vw) rotateX(-1deg) rotateY(2deg);
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    /* display: block;
    min-height: ${(props) => `${props.height || 30}vh`};
    z-index: 3;
    position: relative;
    @media screen and (max-width: 768px) {
      width: 100%;
      min-height: ${(props) =>
      props.minHeight ? `${props.minHeight}px` : "auto"};
      height: ${(props) => `${props.mheight || 30}vh`};
    } 
    &:before {
      min-height: ${(props) => `${props.height || 30}vh`};
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background: #000;
      transform-origin: bottom left;
      transform: translateZ(0);
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
      background: url("/images/Pattern_StarsSquad.png");
      background-repeat: repeat;
      background-position: center center;
      ${(props) =>
      props.image
        ? css`
            background: url("/images/shipPatt.png"),
              radial-gradient(
                circle,
                rgba(0, 0, 0, 0.7) 40%,
                rgba(0, 0, 0, 0.4) 100%
              ),
              url(${props.image});
            background-size: auto, auto, cover;
            background-repeat: repeat, no-repeat, no-repeat;
          `
        : css`
            background-color: ${colors.primary[500]};
          `};

      @media screen and (max-width: 768px) {
        min-height: ${(props) =>
      props.minHeight ? `${props.minHeight}px` : "auto"};
        height: ${(props) => `${props.mheight || 30}vh`};
      }
    }

    &:after {
      content: " ";
      position: absolute;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
      min-height: ${(props) => `${props.height || 30}vh`};
      width: 100%;
      z-index: 1;
      top: -1vh;
      left: 0;
      background-color: ${colors.secondary[500]};
      @media screen and (max-width: 768px) {
        min-height: ${(props) =>
      props.minHeight ? `${props.minHeight}px` : "auto"};
        height: ${(props) => `${props.mheight || 30}vh`};
      }
    }
    @keyframes gradient {
      0% {
        background-position: 0% 1000000px;
      }

      100% {
        background-position: 1000000px 0;
      }
    } */
  }
`;
export const HeaderContainerWrap = styled.div`
  && {
    display: flex;
    width: 90%;
    margin: ${(props) => (props.noCard ? "0" : "0 5%")};
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* min-height: ${(props) => `${props.height || 30}vh`}; */
    /* position: absolute; */
    padding: 3em 0;
    z-index: 9;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-top: 0;
      .headerText {
        text-align: center;
      }
    }
  }
`;
