import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay
} from "@chakra-ui/react";
import { getGlobalState } from "../store";
import { PlayerImage, PlayerName } from "./styled";
import RenderDevices from "./Tabs/Devices";
import RenderEdit from "./Tabs/Edit";
import HistoryView from "./Tabs/History";
import View from "./Tabs/View";

export default function Edit(props) {
  const types = getGlobalState("userTypes");
  const [details, setDetails] = useState(props.data || {});
  const loggedIn = getGlobalState("loggedIn");
  const [view, setView] = useState(
    !props.data || !props.data._id ? "edit" : "view"
  );
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value });
  };

  const RenderHeader = ({ height = 40 }) => {
    return (
      <Box margin={"-.5rem -1.5rem"}>
        <PlayerImage image={details.image} height={height}>
          <Navigation />
          <Flex
            justifyContent={"left center"}
            alignItems={"center"}
            height={`${height}vh`}
          >
            <PlayerName>
              {details.firstName || ""}
              <br />
              {details.lastName || ""}
            </PlayerName>
          </Flex>
        </PlayerImage>
      </Box>
    );
  };
  const Navigation = () => {
    return (
      <Flex position={"absolute"} top={"1em"} right={"1em"}>
        <Button
          size={"sm"}
          mr={1}
          colorScheme={view === "view" ? "primary" : "white"}
          variant={view === "view" ? "solid" : "outline"}
          onClick={() => setView("view")}
        >
          Profile
        </Button>
        <Button
          size={"sm"}
          colorScheme={view === "devices" ? "primary" : "white"}
          variant={view === "devices" ? "solid" : "outline"}
          mr={1}
          onClick={() => {
            setView("devices");
          }}
        >
          Devices
        </Button>
        {((loggedIn && loggedIn.admin && loggedIn.client === details.client) ||
          details._id === loggedIn._id) && (
          <Button
            size={"sm"}
            colorScheme={view === "history" ? "primary" : "white"}
            variant={view === "history" ? "solid" : "outline"}
            mr={1}
            onClick={() => {
              setView("history");
            }}
          >
            History
          </Button>
        )}
        {((loggedIn && loggedIn.role && loggedIn.role === "global") ||
          (loggedIn && loggedIn.admin && details.client === loggedIn.client) ||
          details._id === loggedIn._id) && (
          <Button
            size={"sm"}
            colorScheme={view === "edit" ? "primary" : "white"}
            variant={view === "edit" ? "solid" : "outline"}
            mr={1}
            onClick={() => {
              setView("edit");
            }}
          >
            Edit
          </Button>
        )}
      </Flex>
    );
  };
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {/* <DrawerHeader borderBottomWidth="0"></DrawerHeader> */}
        <DrawerBody overflow={"auto"}>
          {view === "edit" && (
            <>
              <RenderHeader />
              <RenderEdit
                handleFormFieldChange={handleFormFieldChange}
                types={types}
                details={details}
              />
            </>
          )}
          {view === "view" && (
            <>
              <RenderHeader height={65} />
              <View details={details} />
            </>
          )}
          {view === "devices" && (
            <>
              <RenderHeader />
              <RenderDevices
                data={details.devices}
                userId={details._id}
                setData={data =>
                  handleFormFieldChange("devices", {
                    target: { value: data }
                  })
                }
              />
            </>
          )}
          {view === "history" && (
            <>
              <RenderHeader />
              <HistoryView userId={details._id} devices={details.devices} />
            </>
          )}
        </DrawerBody>
        <DrawerFooter borderTopWidth="0">
          {view === "edit" &&
          ((loggedIn && loggedIn.admin && loggedIn.role == "global") ||
            (loggedIn &&
              loggedIn.admin &&
              details.client === loggedIn.client) ||
            details._id === loggedIn._id) ? (
            <Button
              variant="outline"
              colorScheme="primary"
              mr={3}
              onClick={() => props.del([details._id])}
            >
              Delete
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Close
          </Button>
          {((view === "edit" || view === "devices") &&
            (((loggedIn && loggedIn.admin && loggedIn.role == "global")) ||
          (loggedIn && loggedIn.admin && details.client === loggedIn.client) ||
          details._id === loggedIn._id)) ? (
            <Button
              colorScheme="secondary"
              onClick={() => {
                props.set({ ...details }, ` updating user`, "users");
                props.setOpenModal();
              }}
            >
              Save
            </Button>
          ) : (
            ""
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
