import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { BiCalendarWeek, BiWorld } from "react-icons/bi";
import { BsBarChartFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { FiUserPlus } from "react-icons/fi";
import { MdViewWeek } from "react-icons/md";
import { RiTeamLine, RiUser6Line, RiUserSettingsLine } from "react-icons/ri";
import { SiGooglecalendar } from "react-icons/si";
import Header from "../components/header";
import { Board } from "../components/styled";
import { setRemoteCall } from "../remote";
import { setGlobalState, setStorageState, useGlobalState } from "../store";
import { colors } from "../theme";
import Edit from "./Edit";
import EditTypes from "./EditTypes";
import { PlayerValue, RowDashPage } from "./styled";

export default function Users(props) {
  const [openModal, setOpenModal] = useState(false);
  const [openTypesModal, setOpenTypesModal] = useState(false);
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn"); //dont remove

  const [queryDate, setQueryDate] = useState(moment().format("YYYY-MM-DD"));
  const [range, setRange] = useState("month");
  const [rangeType, setRangeType] = useState(
    loggedIn.client ? "team" : "individual"
  );
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [clients] = useGlobalState("clients");
  const [users] = useGlobalState("users");
  const [types] = useGlobalState("userTypes");
  setGlobalState("pageClass", "users");

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  };

  /**
   * get table data
   */
  const getRanks = useCallback(() => {
    let startDate, endDate;
    const queryMomentDate = moment(queryDate);
    switch (range) {
      case "trimester":
        const thisMonth = queryMomentDate.format("M");
        if (thisMonth <= 3) {
          startDate = queryMomentDate
            .month("January")
            .startOf("month")
            .format("YYYY-MM-DD");

          endDate = queryMomentDate
            .month("March")
            .endOf("month")
            .format("YYYY-MM-DD");
        } else if (thisMonth <= 6) {
          startDate = queryMomentDate
            .month("April")
            .startOf("month")
            .format("YYYY-MM-DD");

          endDate = queryMomentDate
            .month("June")
            .endOf("month")
            .format("YYYY-MM-DD");
        } else if (thisMonth <= 9) {
          startDate = queryMomentDate
            .month("July")
            .startOf("month")
            .format("YYYY-MM-DD");

          endDate = queryMomentDate
            .month("September")
            .endOf("month")
            .format("YYYY-MM-DD");
        } else if (thisMonth <= 12) {
          startDate = queryMomentDate
            .month("October")
            .startOf("month")
            .format("YYYY-MM-DD");

          endDate = queryMomentDate
            .month("Dezember")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

        break;
      case "month":
        startDate = queryMomentDate.startOf("month").format("YYYY-MM-DD");

        endDate = queryMomentDate.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        const thisMonth1 = queryMomentDate.format("M");
        if (thisMonth1 <= 8) {
          startDate = moment()
            .set("month", 8)
            .set("day", 1)
            .startOf("month")
            .subtract(1, "year")
            .format("YYYY-MM-DD");

          endDate = moment().set("month", 8).set("day", 1).format("YYYY-MM-DD");
        } else {
          startDate = moment()
            .set("month", 8)
            .set("day", 1)
            .startOf("month")
            .format("YYYY-MM-DD");

          endDate = moment()
            .set("month", 8)
            .set("day", 1)
            .add(1, "year")
            .format("YYYY-MM-DD");
        }

        break;
    }

    setRemoteCall(
      "getRanks",
      {
        start: startDate,
        end: endDate,
        type: rangeType,
        client: rangeType === "team" ? loggedIn.client : null,
      },
      "",
      (cb) => setData(cb)
    );
  }, [setData, range, rangeType, queryDate]);

  useEffect(() => {
    getRanks();
  }, [range, rangeType, queryDate]);
  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall("setUsers", data, notice, (cb) => {
      if (data._id === loggedIn._id) {
        setLoggedIn({ ...loggedIn, ...cb });
        setStorageState("loggedIn", { ...loggedIn, ...cb });
      }
      getRanks();
    });
  };

  /**
   * Del table data
   */
  const del = (data, notice) => {
    setIsOpen({
      title: "Delete User",
      text: "Are you sure you want to delete this user?",
      cb: () => {
        setRemoteCall("delUsers", data, notice);
        getRanks();
        setIsOpen(false);
        if (data.includes(loggedIn._id)) {
          setStorageState("loggedIn", false);
          setLoggedIn(false);
          window.location = "/";
        }
      },
    });
  };

  const updateUserStatus = async (id, status) => {
    setIsOpen({
      title: "Update User Status",
      text: "Are you sure you want to update this user status?",
      cb: () => {
        setRemoteCall("updateUserStatus", { id, status }, null, (data) => {
          getRanks();
          setIsOpen(false);
        });
      },
    });
  };

  const toggleAdmin = async (id, admin) => {
    setIsOpen({
      title: "Update admin status",
      text: admin
        ? "Are you sure you want to make the user an admin?"
        : "Are you sure you want to remove the user from admin",
      cb: () => {
        setRemoteCall("toggleUserAdmin", { id, admin }, null, (data) => {
          getRanks();
          setIsOpen(false);
        });
      },
    });
  };

  const removeClient = async (id) => {
    setIsOpen({
      title: "Remove user from team",
      text: "Are you sure you want to remove the user from the team",
      cb: () => {
        setRemoteCall("removeUserClient", { id }, null, (data) => {
          getRanks();
          setIsOpen(false);
        });
      },
    });
  };

  /**
   * team card
   */
  const TeamCard = ({ user, i }) => {
    return (
      <Board
        as={motion.div}
        margin={"0 3% 3% 0"}
        variants={item}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        active={loggedIn._id === user._id}
        noPointer={true}
      >
        <Flex
          alignContent={"center"}
          className={"content"}
          alignItems={"center"}
        >
          <Flex
            alignContent={"center"}
            className={"content"}
            alignItems={"center"}
            width={"75%"}
          >
            <Box
              display={["block", "block", "none", "block", "block"]}
              marginRight={"1em"}
              // onClick={() => setOpenModal(user)}
            >
              {user.image ? (
                <Box
                  borderRadius={"50%"}
                  height={"6em"}
                  width={"6em"}
                  background={`url(${user.image})`}
                  backgroundPosition={"center"}
                  backgroundSize={"cover"}
                  // border={".3em solid white"}
                  margin={"-2em 0 -2em -2em"}
                />
              ) : (
                <Icon
                  as={FaUserCircle}
                  color={colors.secondary[500]}
                  height={"6em"}
                  width={"6em"}
                  margin={"-2em 0 -2em -2em"}
                />
              )}
            </Box>
            <Box
              color={loggedIn._id === user._id ? "white" : colors.primary[500]}
            >
              <PlayerValue>
                {user.name}
                <span>{user?.users?.length} players</span>
              </PlayerValue>
            </Box>
          </Flex>
          <Box
            width={"25%"}
            textAlign={"center"}
            margin={0}
            color={loggedIn._id === user._id ? "white" : colors.primary[500]}
          >
            <Heading fontSize={"3em"} lineHeight={1}>
              {i + 1}
            </Heading>
            <Flex alignItems={"center"} justifyContent={"center"}>
              <BsBarChartFill
                fill={loggedIn._id === user._id ? "white" : colors.primary[500]}
              />
              <Text
                opacity={".6"}
                fontSize={".9em"}
                fontWeight={"bold"}
                ml={".2em"}
              >
                {user?.points?.toFixed(1)}
              </Text>
            </Flex>
          </Box>
          {/* )} */}
        </Flex>
      </Board>
    );
  };
  /**
   * player card
   */
  const PlayerCard = ({ user, i }) => {
    return (
      <Board
        as={motion.div}
        margin={"0 3% 3% 0"}
        variants={item}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        active={loggedIn._id === user._id}
      >
        <Flex
          alignContent={"center"}
          className={"content"}
          alignItems={"center"}
        >
          <Flex
            alignContent={"center"}
            className={"content"}
            alignItems={"center"}
            width={"75%"}
          >
            <Box
              display={["block", "block", "none", "block", "block"]}
              marginRight={"1em"}
              onClick={() => setOpenModal(user)}
            >
              {user.image ? (
                <Box
                  borderRadius={"50%"}
                  height={"6em"}
                  width={"6em"}
                  background={`url(${user.image})`}
                  backgroundPosition={"center"}
                  backgroundSize={"cover"}
                  // border={".3em solid white"}
                  margin={"-2em 0 -2em -2em"}
                />
              ) : (
                <Icon
                  as={FaUserCircle}
                  color={colors.secondary[500]}
                  height={"6em"}
                  width={"6em"}
                  margin={"-2em 0 -2em -2em"}
                />
              )}
            </Box>
            <Box
              color={loggedIn._id === user._id ? "white" : colors.primary[500]}
            >
              <PlayerValue onClick={() => setOpenModal(user)}>
                {user.firstName} {user.lastName}
                <span>
                  {user.status ? "Active -" : "Inactive -"}{" "}
                  {user.client?.name || "global"}
                </span>
              </PlayerValue>
            </Box>
          </Flex>
          <Box
            width={"25%"}
            textAlign={"center"}
            margin={0}
            onClick={() => setOpenModal(user)}
            color={loggedIn._id === user._id ? "white" : colors.primary[500]}
          >
            <Heading fontSize={"3em"} lineHeight={1}>
              {i + 1}
            </Heading>
            <Flex alignItems={"center"} justifyContent={"center"}>
              <BsBarChartFill
                fill={loggedIn._id === user._id ? "white" : colors.primary[500]}
              />
              <Text
                opacity={".6"}
                fontSize={".9em"}
                fontWeight={"bold"}
                ml={".2em"}
              >
                {user?.points?.toFixed(1)}
              </Text>
            </Flex>
          </Box>
          {/* )} */}
        </Flex>
      </Board>
    );
  };

  //  limit list to 100 players
  // is loggedIn user in not add it to the end of the list

  return (
    <div className={"users dashboard"}>
      <Header image={"/images/ranks.jpg"} height={"300px"}>
        <Flex
          alignContent={"space-between"}
          alignItems={"center"}
          flexDirection={["column", "column", "row", "row", "row"]}
          width={"100%"}
        >
          <Box
            width={{ base: "100%", md: "80%" }}
            mb={["1em", "1em", "0", "0", "0"]}
            className={"headerText"}
          >
            <Heading as={"h1"} size="2xl" color={colors.primary[500]}>
              {range} Ranks
            </Heading>
            <Heading as={"h3"} size="md" color={colors.secondary[500]}>
              {rangeType} user ranks{" "}
              <Input
                display={"inline-block"}
                value={queryDate}
                height={"1.7em"}
                margin={0}
                width={"155px"}
                type={"date"}
                onChange={(e) => setQueryDate(e.target.value)}
              />
            </Heading>
          </Box>

          <Flex marginTop={["1em", "1em", 0, 0, 0]}>
            {loggedIn && loggedIn.admin && loggedIn.role === "global" ? (
              <Button
                colorScheme="secondary"
                width={"100%"}
                ml={".4em"}
                _hover={{
                  background: "primary.500",
                }}
                onClick={() => setOpenTypesModal(true)}
              >
                <RiUserSettingsLine size={20} />
                User Types
              </Button>
            ) : (
              ""
            )}
            {(loggedIn &&
              loggedIn.client &&
              loggedIn.status === 1 &&
              loggedIn.admin) ||
            (loggedIn.role === "global" && loggedIn.admin) ? (
              <Button
                colorScheme="secondary"
                width={"100%"}
                ml={".4em"}
                _hover={{
                  background: "primary.500",
                }}
                onClick={() => setOpenModal(true)}
              >
                <FiUserPlus size={25} />
                New User
              </Button>
            ) : (
              ""
            )}
          </Flex>
        </Flex>
      </Header>

      <Box display={["block"]} className={"wrapper"}>
        <Box mb={"4vh"}>
          <Flex w={"100%"} justifyContent={"center"}>
            <ButtonGroup isAttached variant="solid" mr={"1em"}>
              <Tooltip label="Individual">
                <Button
                  colorScheme={
                    rangeType === "individual" ? "primary" : "secondary"
                  }
                  value="Submit!"
                  width={"100%"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => setRangeType("individual")}
                >
                  <RiUser6Line size={20} />
                </Button>
              </Tooltip>
              <Tooltip label="Team">
                <Button
                  colorScheme={rangeType === "team" ? "primary" : "secondary"}
                  value="Submit!"
                  width={"100%"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => setRangeType("team")}
                >
                  <RiTeamLine size={20} />
                </Button>
              </Tooltip>{" "}
              <Tooltip label="Team Ranks">
                <Button
                  colorScheme={
                    rangeType === "clients" ? "primary" : "secondary"
                  }
                  value="Submit!"
                  width={"100%"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => setRangeType("clients")}
                >
                  <BiWorld size={20} />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <ButtonGroup isAttached variant="solid">
              <Tooltip label="Month">
                <Button
                  colorScheme={range === "month" ? "primary" : "secondary"}
                  value="Submit!"
                  width={"100%"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => {
                    setRange("month");
                    setQueryDate(new Date());
                  }}
                >
                  <SiGooglecalendar size={15} />
                  {/* <span style={{ marginLeft: ".4em" }}>Month</span> */}
                </Button>
              </Tooltip>
              <Tooltip label="Trimester">
                <Button
                  colorScheme={range === "trimester" ? "primary" : "secondary"}
                  value="Submit!"
                  width={"100%"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => {
                    setRange("trimester");
                    setQueryDate(new Date());
                  }}
                >
                  <MdViewWeek size={35} />
                  {/* <span style={{ marginLeft: ".4em" }}>Trimester</span> */}
                </Button>
              </Tooltip>
              <Tooltip label="Year">
                <Button
                  colorScheme={range === "year" ? "primary" : "secondary"}
                  value="Submit!"
                  width={"100%"}
                  _hover={{
                    background: "primary.500",
                  }}
                  onClick={() => setRange("year")}
                >
                  <BiCalendarWeek size={20} />
                  {/* <span style={{ marginLeft: ".4em" }}>Year</span> */}
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Flex>
        </Box>
        <RowDashPage
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          {data &&
            data.map((user, i) => {
              if (rangeType === "clients") {
                return <TeamCard key={i} i={i} user={user} />;
              }
              if (i > 1000 && user._id !== loggedIn._id) return null;
              return <PlayerCard key={i} i={i} user={user} />;
            })}
        </RowDashPage>
      </Box>

      <AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent background={"white"}>
            <AlertDialogHeader fontSize="xl" fontWeight="bold">
              {isOpen && isOpen.title}
            </AlertDialogHeader>

            <AlertDialogBody>{isOpen && isOpen.text}</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button onClick={() => isOpen && isOpen.cb()} ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {openTypesModal && (
        <EditTypes
          data={openModal}
          set={set}
          del={del}
          setOpenModal={setOpenTypesModal}
        />
      )}

      {openModal && (
        <Edit
          data={
            openModal.client
              ? {
                  ...openModal,
                  client: openModal.client?._id,
                }
              : openModal
          }
          set={set}
          del={del}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
}
