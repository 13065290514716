import React from "react";
import EditClient from "../Clients/Edit";
import { useGlobalState } from "../store";
import EditProfile from "../users/EditProfile";

const RenderModal = props => {
  const [userDetails, setUserDetails] = useGlobalState("editProfile"); //dont remove
  const [teamDetails, setTeamDetails] = useGlobalState("editTeam"); //dont remove

  return (
    <>
      {userDetails && (
        <EditProfile
          userDetails={userDetails}
          setUserDetails={setUserDetails}
        />
      )}
      {teamDetails && (
        <EditClient teamDetails={teamDetails} setOpenModal={setTeamDetails} />
      )}
    </>
  );
};

export default RenderModal;
