import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import Upload from "../components/Upload";
import { getGlobalState } from "../store";

export default function Edit(props) {
  const loggedIn = getGlobalState("loggedIn");
  const users = getGlobalState("users");
  const userTypes = getGlobalState("userTypes");

  const [details, setDetails] = useState({
    status: props.status || 1,
    date: new Date(),
    user: loggedIn._id,
    ...props.data,
  });

  const [view] = useState("info");

  const save = () => {
    props.set({ ...details }, ` sending news item`, "calendar");
    props.setOpenModal();
  };
  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value });
  };
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0">Edit News</DrawerHeader>

        <DrawerBody overflow={"auto"}>
          <Box mt={"2em"} />
          {props.form &&
            view === "info" &&
            props.form.map((k, i) => {
              if (k.k === "_id" || k.k === "history") return null;

              if (loggedIn && !loggedIn.admin && k.k === "date") return null;

              if (loggedIn && !loggedIn.admin && k.k === "status") return null;
              if (k.k === "role" && props.types)
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <InputLeftAddon
                      minWidth={"120px"}
                      textAlign={"center"}
                      padding={"1.5em .5em"}
                      children={k.l}
                      fontWeight={"bold"}
                    />
                    <Select
                      height="3em"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        (t) =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                );
              if (k.t === "upload") {
                return (
                  <Box margin={"2em 0"} textAlign={"center"}>
                    {details[k.k] && details[k.k].match(/.jpg|.png|.gif/) ? (
                      <img
                        style={{
                          width: "100%",
                          height: "20vh",
                          objectFit: "contain",
                        }}
                        alt={""}
                        src={details[k.k]}
                      />
                    ) : (
                      <Box margin={"2em 0"}>
                        <a href={details[k.k]} target={"_blank"}>
                          {details[k.k]}
                        </a>
                      </Box>
                    )}
                    <Upload
                      key={i}
                      callback={(files) =>
                        handleFormFieldChange(k.k, { target: { value: files } })
                      }
                    />
                  </Box>
                );
              }
              if (k.k === "user")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="How is this request for"
                      value={details[k.k]}
                    >
                      {users &&
                        users.map((us) => {
                          let userType;
                          if (userTypes)
                            userType = userTypes.find((c) => c._id === us.role);

                          return (
                            <option value={us._id}>
                              {us.firstName || ""} {us.lastName || ""} -{" "}
                              {userType ? userType.name : "global"}
                            </option>
                          );
                        })}
                    </Select>
                  </InputGroup>
                );
              if (k.k === "status")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Status"
                      value={details[k.k]}
                    >
                      <option value={0}> Inactive </option>
                      <option value={1}> Active </option>
                    </Select>
                  </InputGroup>
                );

              if (k.k === "status")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder="Status"
                      value={details[k.k]}
                    >
                      <option value={0}> Inactive </option>
                      <option value={1}> Active </option>
                    </Select>
                  </InputGroup>
                );

              if (k.t === "textarea") {
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Textarea
                      padding={"1.5em .5em"}
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                );
              }
              if (k.t === "date") {
                const thisDate = moment(details[k.k]).format("YYYY-MM-DD");
                return (
                  <Box key={i}>
                    <Input
                      size="lg"
                      onChange={(event) => handleFormFieldChange(k.k, event)}
                      type={k.t || "text"}
                      placeholder={k.l}
                      value={thisDate}
                    />
                  </Box>
                );
              }
              return (
                <Input
                  size="lg"
                  onChange={(event) => handleFormFieldChange(k.k, event)}
                  type={k.t || "text"}
                  placeholder={k.l}
                  value={details[k.k]}
                />
              );
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="0">
          {loggedIn && loggedIn.admin && loggedIn.role == "global" && (
            <Button
              variant="outline"
              colorScheme="primary"
              mr={3}
              onClick={() => props.del([details._id])}
            >
              Delete
            </Button>
          )}
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancel
          </Button>
          <Button
            colorScheme="secondary"
            onClick={() => {
              save();
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
