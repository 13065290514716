import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Box, Button, Flex, Input, useToast } from "@chakra-ui/react";
import { setGlobalState } from "../store";

function Reset(props) {
  const toast = useToast();
  const [password, setPassword] = useState("");
  setGlobalState("pageClass", "login");
  // getGlobalState()

  const resetPassword = async () => {
    const token = props.match.params.token;
    try {
      const response = await axios.post(`/auth/reset/${token}`, {
        password,
      });
      if (response.data && response.data.msg) {
        props.history.push("/");
      } else {
        toast({
          title: `Not a valid Token, please verify the link and try again`,
        });
      }
    } catch (e) {
      console.log(e);
      toast({
        title: `Not a valid Token, please verify the link and try again`,
      });
      setGlobalState("loggedIn", null);
    }
  };

  const handleFormFieldChange = (key, { target: { value } }) => {
    setPassword(value);
  };

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
      backgroundImage={"url(/images/home.jpg)"}
      backgroundPosition={"center"}
      backgroundSize={"cover"}
    >
      <Box
        maxW="md"
        bg="black"
        position={"absolute"}
        bottom={"10vh"}
        width={["100%", "100%", "40%", "40%", "40%"]}
        padding={"2em"}
        shadow="md"
        rounded="lg"
        overflow="hidden"
      >
        <Box className={"login-container"} width={"100%"}>
          <Box textAlign={"center"}>
            <h1 style={{ color: "white" }}>Reset Password</h1>
            <form className="login-form" data-login-form>
              <Input
                type="password"
                data-login-password
                onChange={(event) => handleFormFieldChange("password", event)}
                placeholder="New Password"
              />

              <Button
                onClick={(e) => resetPassword(e)}
                variantColor="teal"
                value="Submit!"
                width={"100%"}
              >
                Set new password!
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export { Reset };
export default withRouter(Reset);
