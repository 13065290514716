import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaSmog } from "react-icons/fa";
import { ImPowerCord } from "react-icons/im";
import { SiGumtree } from "react-icons/si";
import { setRemoteCall } from "../../remote";
import { getGlobalState, useGlobalState } from "../../store";

const Status = ({ total, outputDataperDevice }) => {
  const loggedIn = getGlobalState("loggedIn");
  const [points, setPoints] = useGlobalState("points");

  const getPlayerPoints = newSearch => {
    setRemoteCall(
      "getPoints",
      {
        user: loggedIn?._id
      },
      "",
      cb => {
        setPoints(cb);
      }
    );
  };

  useEffect(() => {
    getPlayerPoints();
  }, []);
  return (
    <>
      <Flex justifyContent={"center"} fontSize={{ base: ".8em", xl: "1em" }}>
        <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
          <Box display={{ base: "none", md: "block" }}>
            <ImPowerCord fill={"#fff"} size={30} />
          </Box>
          <Box ml={".5em"} fontSize={"1em"} color={"#fff"}>
            <Heading lineHeight={1} mb={0} color={"#fff"}>
              {total && (total.power / 1000).toFixed(1)}
            </Heading>
            <Text
              fontSize={"1em"}
              fontWeight={"bold"}
              color={"#fff"}
              lineHeight={1}
              mt={0}
              opacity={".5"}
            >
              Mw/h
            </Text>
          </Box>
        </Flex>

        {/* <Board width={100} margin={"0 2em 0 0"} mMargin={"0 2em 0 0"}>
          <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
            <Box display={{ base: "none", md: "block" }}>
              <BsCalendar4Week size={25} />
            </Box>
            <Box ml={".8em"}>
              <Heading
                fontSize={{ base: "1.3em", sm: "1.3em" }}
                lineHeight={1}
                mb={0}
              >
                {total && total.planned && total.planned.toFixed(1)}h
              </Heading>
              <Text
                fontSize={".8em"}
                fontStyle={"italic"}
                fontWeight={"bold"}
                lineHeight={1}
                mt={0}
                opacity={".5"}
              >
                {total && total.hours && total.hours.toFixed(1)}h
              </Text>
            </Box>
          </Flex>
        </Board> */}
        <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
          <Box display={{ base: "none", md: "block" }}>
            <FaSmog size={30} color={"#fff"} />
          </Box>
          <Box ml={".5em"} fontSize={"1em"} color={"#fff"}>
            <Heading lineHeight={1} mb={0} color={"#fff"}>
              {total && total.co2 && total.co2.toFixed(1)}
            </Heading>
            <Text
              fontSize={".8em"}
              color={"#fff"}
              fontWeight={"bold"}
              lineHeight={1}
              mt={0}
              opacity={".5"}
            >
              KgCo2
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
          <Box display={{ base: "none", md: "block" }}>
            <SiGumtree size={30} color={"#fff"} />
          </Box>
          <Box ml={".5em"} fontSize={"1em"} color={"#fff"}>
            <Heading lineHeight={1} mb={0} color={"#fff"}>
              {total && total.trees && total.trees.toFixed(1)}
            </Heading>
            <Text
              fontSize={".8em"}
              fontWeight={"bold"}
              lineHeight={1}
              mt={0}
              opacity={".5"}
            >
              trees
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Status;
