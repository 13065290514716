import React from 'react'

const Calendar = props => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'calendar'}</title>
      <path
        d="M.376 37.794c0 .863.697 1.56 1.56 1.56h35.88c.863 0 1.56-.697 1.56-1.56V17.319h-39v20.475zm37.44-33.93h-8.19V.744a.391.391 0 00-.39-.39h-2.73a.391.391 0 00-.39.39v3.12h-12.48V.744a.391.391 0 00-.39-.39h-2.73a.391.391 0 00-.39.39v3.12h-8.19c-.863 0-1.56.697-1.56 1.56v8.58h39v-8.58c0-.863-.697-1.56-1.56-1.56z"
        fill={props.fill || '#C5C5C5'}
        fillRule="nonzero"
      />
    </svg>
  )
}
export default Calendar
