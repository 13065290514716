import { extendTheme, theme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
// import Color from 'color'
import tinycolor from "tinycolor2";
import { getPresistData } from "./util";

// let load custom theme
const logData = getPresistData("g4pApp", "loggedIn");
let primary = "#101820";
let secondary = "#1b7c5f"; //teste #f1c138

if (logData && logData.theme) {
  // primary = logData.theme.primary;
  secondary = logData.theme.primary;
}

const reloadColor = (color) => {
  if (color) secondary = color;

  return {
    white: {
      600: "#fff",
      500: "#fff",
    },
    primary: {
      500: tinycolor(primary).toRgbString(),
      400: tinycolor(primary).lighten(10).toRgbString(),
      300: tinycolor(primary).lighten(20).toRgbString(),
      200: tinycolor(primary).lighten(30).toRgbString(),
      100: tinycolor(primary).lighten(40).toRgbString(),
      50: tinycolor(primary).lighten(70).toRgbString(),
      transparent: {
        90: tinycolor(primary).setAlpha(0.9).toRgbString(),
        80: tinycolor(primary).setAlpha(0.8).toRgbString(),
        40: tinycolor(primary).setAlpha(0.4).toRgbString(),
        30: tinycolor(primary).setAlpha(0.3).toRgbString(),
        20: tinycolor(primary).setAlpha(0.2).toRgbString(),
        10: tinycolor(primary).setAlpha(0.1).toRgbString(),
      },
    },
    secondary: {
      500: tinycolor(secondary).toRgbString(),
      400: tinycolor(secondary).lighten(10).toRgbString(),
      300: tinycolor(secondary).lighten(20).toRgbString(),
      200: tinycolor(secondary).lighten(30).toRgbString(),
      100: tinycolor(secondary).lighten(40).toRgbString(),
      50: tinycolor(secondary).lighten(50).toRgbString(),
      transparent: {
        90: tinycolor(secondary).setAlpha(0.9).toRgbString(),
        80: tinycolor(secondary).setAlpha(0.8).toRgbString(),
        70: tinycolor(secondary).setAlpha(0.7).toRgbString(),
        60: tinycolor(secondary).setAlpha(0.6).toRgbString(),
        50: tinycolor(secondary).setAlpha(0.5).toRgbString(),
        40: tinycolor(secondary).setAlpha(0.4).toRgbString(),
        30: tinycolor(secondary).setAlpha(0.3).toRgbString(),
        20: tinycolor(secondary).setAlpha(0.2).toRgbString(),
        10: tinycolor(secondary).setAlpha(0.1).toRgbString(),
      },
    },
  };
};
let colors = reloadColor();

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
});

const getNewOver = (newDarkmode, color) => {
  let darkMode = logData?.attr?.darkMode === "dark";
  colors = reloadColor(color);

  if (newDarkmode && (newDarkmode === "dark" || newDarkmode === "light")) {
    darkMode = newDarkmode === "dark";
  }
  return extendTheme({
    ...theme,
    breakpoints,
    colors: {
      ...theme.colors,
      ...colors,
      transparent: "transparent",
      black: "#000",
    },
    components: {
      Button: {
        baseStyle: {
          cursor: "pointer",
          boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
          _hover: {},
        },
        sizes: {
          lg: {
            fontSize: ".9em",
          },
          sm: {
            color: colors.primary[500],
          },
        },
        colorSchemes: {
          primary: {},
        },
        variants: {},
      },
      Select: {
        colorScheme: {
          primary: {},
        },
      },
      Alert: {
        baseStyle: {
          color: "white",
        },
      },
      Toast: {
        baseStyle: {
          color: "white",
        },
      },
      ProgressBar: {
        baseStyle: {
          color: colors.primary[500],
          border: 0,
          boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
        },
        sizes: {
          sm: {
            background: colors.primary[500],
            border: 0,
            boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
          },
        },
      },
      Drawer: {
        baseStyle: {
          header: {
            fontSize: "2em",
            color: !darkMode ? `${colors.primary[500]} !important` : "#fff",
            // fontStyle: "italic"
          },
          dialog: {
            background: darkMode ? colors.primary[500] : "#fff",
            color: !darkMode ? `${colors.primary[500]} !important` : "#fff",
          },
        },
      },

      Input: {
        baseStyle: {
          color: colors.primary,
          background: colors.primary[400],
          border: 0,
          boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
        },
        sizes: {
          md: {
            border: 0,
            background: colors.primary[400],
            boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
          },
        },
      },
    },
    radii: {
      none: "15px",
      md: "15px",
      base: "15px",
    },
    fonts: {
      heading: '"RiftSoft", sans-serif',
      body: '"RiftSoft", sans-serif',
    },
    styles: {
      global: (props) => ({
        html: {
          color: colors.secondary[500],
          background: "#f7fafc",
        },
        body: {
          color: colors.secondary[500],
          background: `url('/images/leafs.jpg') center right `,
          backgroundSize: "100%",
          backgroundRepeat: "repeat-y",
        },
        a: {},
        "h1,h2,h3,h4,h5,h6": {
          // fontStyle: "italic"
        },
        ".chakra-toast": {
          color: "white!important",
        },
        input: {
          boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
          border: "0!important",
          background: `rgba(255,255,255,.1)!important`,
          background: !darkMode
            ? `rgba(255,255,255,1)!important`
            : `rgba(255,255,255,.1)!important`,
          color: !darkMode ? colors.primary[500] : "#fff",
          fontSize: "1em",
        },
        select: {
          boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
          margin: " 0",
          color: !darkMode ? colors.primary[500] : "#fff",
          border: "0!important",
          background: !darkMode
            ? `rgba(255,255,255,1)!important`
            : `rgba(255,255,255,.1)!important`,
          borderRadius: "15px!important",
          height: "3.5em!important",
          // color: colors.secondary[500],
          fontSize: "1em!important",
        },
        textarea: {
          boxShadow: " 0 2px 9px 0 rgba(120,47,10,0.10)",
          margin: " 0",
          color: !darkMode ? colors.primary[500] : "#fff",
          border: "0!important",
          background: !darkMode
            ? `rgba(255,255,255,1)!important`
            : `rgba(255,255,255,.1)!important`,
          borderRadius: "15px!important",
          // color: colors.secondary[500],
          fontSize: "1em!important",
        },
      }),
    },
  });
};
const over = extendTheme(getNewOver());

export { reloadColor, colors, over, getNewOver };
