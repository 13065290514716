import React from "react";
import { motion } from "framer-motion";
import {
  HeaderContainer,
  HeaderContainerWrap,
  HeaderContainerImg,
  HeaderContainerImgWrap
} from "./styled";
import Navigation from "../navigation";

import { Box } from "@chakra-ui/react";
const head = {
  visible1: { opacity: 1 },
  hidden1: { opacity: 0 }
};

const title = {
  visibleT: { opacity: 1 },
  hiddenT: { opacity: 0 }
};
const Header = props => {
  return (
    <Box
      paddingLeft={props.noCard ? 0 : ["25px", "25px", "60px", "60px", "60px"]}
      mb={"5vh"}
    >
      <Box
        position={"relative"}
        ml={props.noCard ? 0 : ["-25px", "-25px", "-60px", "-60px", "-60px"]}
      >
        <Navigation />
      </Box>
      <HeaderContainerImgWrap height={props.height} minHeight={props.minHeight}>
        <HeaderContainerImg
          height={props.height}
          minHeight={props.minHeight}
          image={props.image}
        />
      </HeaderContainerImgWrap>

      {props.noCard ? (
        <HeaderContainerWrap
          as={motion.div}
          noCard={props.noCard}
          initial="hiddenT "
          animate="visibleT"
          transition={{ ease: "easeOut", delay: 0.5 }}
          variants={title}
        >
          {props.children}
        </HeaderContainerWrap>
      ) : (
        <HeaderContainer
          as={motion.div}
          initial="hidden1"
          animate="visible1"
          variants={head}
          transition={{ ease: "easeOut", duration: 0.5 }}
          image={props.image}
          noCard={props.noCard}
        >
          <HeaderContainerWrap
            as={motion.div}
            initial="hiddenT "
            animate="visibleT"
            transition={{ ease: "easeOut", delay: 0.5 }}
            variants={title}
          >
            {props.children}
          </HeaderContainerWrap>
        </HeaderContainer>
      )}
    </Box>
  );
};

export default Header;
