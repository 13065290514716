import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { Board } from "../../components/styled";
import { DeviceButton } from "../../components/Timer/styled";
import { getGlobalState } from "../../store";
import { colors } from "../../theme";
import Joypad from "../icons/Joypad";
import Mouse from "../icons/Mouse";
import Tv from "../icons/Tv";
const RenderEditTimelog = ({
  i,
  removeLog,
  editConsumption,
  handleFormFieldChange,
  dontEditDevices,
  setLog,
  devices,
  details,
}) => {
  const loggedIn = getGlobalState("loggedIn"); //dont remove
  const toast = useToast();
  /*
    start: new Date(),
    end: new Date(),
    devices: '', 
    task: '',
    status: 0,
    id: null
  */
  const availableDevices = devices ? devices : loggedIn.devices;
  return (
    <Board width={"100%"} active>
      <div>
        <Input
          padding={".5em .5em"}
          height={"3em"}
          onChange={(event) => handleFormFieldChange("task", event)}
          type={"text"}
          placeholder="Task Name"
          value={details.task || "timelog"}
        />

        <InputGroup key={i} margin={"1em 0"} size="sm">
          <Textarea
            padding={"1.5em .5em"}
            onChange={(event) => handleFormFieldChange("details", event)}
            placeholder={"Details"}
            value={details.details}
          />
        </InputGroup>
        <Flex alignItems={"center"}>
          <ButtonGroup width={"100%"} size="md" isAttached>
            <Input
              padding={".5em .5em"}
              height={"3em"}
              onChange={(event) => {
                const thisValue = event.target.value;
                if (moment(thisValue).isAfter(moment(details.end))) {
                  return toast({
                    title: "Invalid date start date is after end date",
                    status: "warning",
                    position: "top",
                  });
                }

                handleFormFieldChange("start", event);
              }}
              type={"datetime-local"}
              value={moment(details.start || new Date()).format(
                "YYYY-MM-DDTHH:mm"
              )}
            />
            <Input
              padding={".5em .5em"}
              height={"3em"}
              onChange={(event) => {
                const thisValue = event.target.value;
                if (moment(thisValue).isBefore(moment(details.start))) {
                  return toast({
                    title: "Invalid date end date is before start date",
                    status: "warning",
                    position: "top",
                  });
                }
                handleFormFieldChange("end", event);
              }}
              type={"datetime-local"}
              value={moment(details.end || new Date()).format(
                "YYYY-MM-DDTHH:mm"
              )}
            />
          </ButtonGroup>
        </Flex>

        <Flex alignItems={"center"} margin={"1em 0"}>
          <Select
            placeholder="Select Status"
            value={details.status}
            onChange={(e) => handleFormFieldChange("status", e)}
          >
            <option value={0}>Finished</option>
            <option value={1}>Ongoing</option>
          </Select>
        </Flex>
        <Flex mt={".8em"} alignItems={"center"} justifyContent={"center"}>
          {availableDevices &&
            availableDevices.map((device, i) => {
              const isActive = details.devices
                ? details.devices.find((d) => d.id === i)
                : false;
              return (
                <Box
                  key={i}
                  cursor={"pointer"}
                  textAlign={"center"}
                  onClick={() => {
                    if (dontEditDevices) return null;
                    if (!isActive) {
                      handleFormFieldChange("devices", {
                        target: {
                          value: [
                            ...(details.devices || []),
                            { ...device, id: i },
                          ],
                        },
                      });
                    } else {
                      handleFormFieldChange("devices", {
                        target: {
                          value: details.devices.filter((d) => d.id !== i),
                        },
                      });
                    }
                  }}
                >
                  <DeviceButton active={isActive}>
                    {device.type === "computer" && (
                      <Mouse fill={"#fff"} height={30} width={25} />
                    )}
                    {device.type === "console" && (
                      <Joypad fill={"#fff"} height={35} width={30} />
                    )}
                    {device.type === "tv" && (
                      <Tv fill={"#fff"} height={30} width={25} />
                    )}
                  </DeviceButton>
                  <Box
                    fontSize={".8em"}
                    color={[
                      colors.secondary[500],
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                    ]}
                  >
                    {device.name}
                  </Box>
                  {editConsumption && (
                    <Box
                      fontSize={".8em"}
                      width={"60px"}
                      color={[
                        colors.secondary[500],
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                      ]}
                    >
                      <Input
                        display={"inline-block"}
                        type="number"
                        value={device.power}
                        onChange={(e) =>
                          handleFormFieldChange("devices", {
                            target: {
                              value: details.devices.map((d) => {
                                if (d.id === i) {
                                  return {
                                    ...d,
                                    power: e.target.value,
                                  };
                                }
                                return d;
                              }),
                            },
                          })
                        }
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
        </Flex>

        {setLog && (
          <Flex marginBottom={"2em"}>
            {/* {removeLog && (
          <Button
            margin={'1em 0'}
            size={'sm'}
            width={'100%'}
            height={'3em'}
            variant={'outline'}
            onClick={() => removeLog(i)}
          >
            - Remove Log
          </Button>
        )} */}
            <Button
              margin={"1em 0 0 1em"}
              size={"sm"}
              width={"100%"}
              height={"3em"}
              onClick={() => setLog(i)}
            >
              + Save Log
            </Button>
          </Flex>
        )}
      </div>
    </Board>
  );
};

export default RenderEditTimelog;
