import styled, { css } from "styled-components";
import { colors } from "../../theme";

export const ChartLine = styled.div`
  && {
    .circle-chart__circle {
      transform: rotate(126deg);
      transform-origin: center;
    }
    .circle-chart {
      display: inline-block;
      width: 100%;
      @media screen and (max-width: 400px) {
        margin-top: 2em;
        height: 320px;
      }
    }
    /* animation: circle-chart-fill 2s reverse; */

    /* @keyframes circle-chart-fill {
      to { stroke-dasharray: 0 100; }
    } */
  }
`;
export const DeviceButton = styled.div`
  && {
    height: 45px;
    margin: 0 0.2em;
    text-align: center;
    background: ${colors.primary[500]};

    /* background: #d8d8d8; */
    /* background-image: linear-gradient(-36deg, #191e26 0%, #353f59 100%); */
    box-shadow: -10px -10px 15px 0 rgba(255, 255, 255, 0.05),
      12px 12px 45px 0 rgba(0, 0, 0, 0.55),
      inset 6px 0 15px 0 rgba(73, 95, 240, 0.18),
      inset 1px 1px 0 0 rgba(255, 255, 255, 0.09);

    border-radius: 10px;
    padding: 0.4em 1em;
    ${props =>
      props.active
        ? css`
            background: #d8d8d8;
            background-image: linear-gradient(
              -29deg,
              ${colors.secondary[500]} 0%,
              ${colors.secondary[300]} 100%
            );
            box-shadow: 8px 10px 18px 0 rgba(0, 0, 0, 0.3),
              inset 1px 1px 1px 0 rgba(255, 255, 255, 0.2);
          `
        : css``}
    @media screen and (max-width: 420px) {
      zoom: 0.8;
    }
  }
`;
export const PartnerButton = styled.div`
  && {
    margin: 0 0.2em;
    text-align: center;
    display: inline-block;
    color: #fff;
    background: ${colors.primary[500]};
    /* background-image: linear-gradient(-29deg, #8a000c 0%, #e10014 100%); */
    box-shadow: 8px 10px 18px 0 rgba(0, 0, 0, 0.3),
      inset 1px 1px 1px 0 rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    cursor: pointer;
    padding: 0.4em 1em;
    ${props =>
      props.active
        ? css`
            background: #d8d8d8;
            background-image: linear-gradient(-29deg, #8a000c 0%, #e10014 100%);
            box-shadow: 8px 10px 18px 0 rgba(0, 0, 0, 0.3),
              inset 1px 1px 1px 0 rgba(255, 255, 255, 0.2);
          `
        : css``}
    @media screen and (max-width: 420px) {
      zoom: 0.8;
    }
  }
`;
export const TimerTypeText = styled.div`
  && {
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
    /* font-style: italic; */
    color: ${colors.primary[500]};
  }
`;
export const TimerText = styled.div`
  && {
    font-size: 5em;
    position: absolute;
    top: 1.5em;
    width: 100%;
    text-align: center;
    font-weight: bold;
    /* font-style: italic; */
    color: ${colors.secondary[500]};
    line-height: 1;
    @media screen and (max-width: 400px) {
      font-size: 3.5em;
      top: 2.5em;
    }
  }
`;

export const PowerButton = styled.div`
  && {
    margin-top: -10em;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    padding: 1.5em;
    /* background: ${colors.secondary[500]}; */
    background: #fff;
    box-shadow: 0px 10px 5px 3px rgba(0, 0, 0, 0.17);
    transition: all 0.5 ease-in;
    ${props =>
      props.active === "active"
        ? css`
            box-shadow: inset 0px 10px 5px 3px rgba(0, 0, 0, 0.17);
          `
        : ``}
    @media screen and (max-width: 400px) {
      .powerSVG{
        width: 40px;
        height: 40px;
      }
        display: inline-block;
       
    }
  }
`;
