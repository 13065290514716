import { Box, Flex, Progress, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaSmog } from "react-icons/fa";
import { ImPowerCord } from "react-icons/im";
import { RiGamepadLine } from "react-icons/ri";
import { MdRecycling } from "react-icons/md";
import { SiGumtree } from "react-icons/si";
import { MetricValue, MetricValueWhite } from "../Requests/styled";
import co2 from "../co2";
import Results from "../components/Status/Results";
import Status from "../components/Status/Status";
import Timer from "../components/Timer";
import Header from "../components/header";
import { Board } from "../components/styled";
import { setRemoteCall } from "../remote";
import { getGlobalState, setGlobalState, useGlobalState } from "../store";
import { colors } from "../theme";
import Joypad from "../users/icons/Joypad";
import Mouse from "../users/icons/Mouse";
import Tv from "../users/icons/Tv";
import { subscribeUser } from "../subscription";
import { RotatedCard, RowDashPage, Wrap } from "./styled";
// const MAINCOLORS = [colors.primary[500], colors.primary[200]]
const SUBCOLORS = [
  colors.secondary[500],
  colors.secondary[400],
  colors.secondary[300],
  colors.secondary[200],
  colors.secondary[100],
];

export default function Dashboard(props) {
  subscribeUser();
  setGlobalState("pageClass", "dashboard");
  const offsets = getGlobalState("offsets");
  const loggedIn = getGlobalState("loggedIn"); //dont remove
  const [requests] = useGlobalState("requests"); //dont remove
  const users = getGlobalState("users");
  // let userClient = null
  // if (loggedIn && !loggedIn.admin) {
  //   userClient = loggedIn.role
  // }
  // const types = getGlobalState('userTypes')
  const [monthTimeLog, setMonthTimeLog] = useState([]);
  const [yearTimeLog, setYearTimeLog] = useState([]);
  const [yearOffset, setYearOffset] = useState([]);
  const [barData, setBarData] = useState([]);
  const [outputDataperDevice, setOutputDataperDevice] = useState([]);
  const [total, setTotal] = useState([]);

  const [printing] = useState(false);
  useEffect(() => {
    getMonthData();
    getYearStats();
  }, []);

  useEffect(() => {
    const output = calcData();
    setOutputDataperDevice(output.outputDataperDevice);
    setTotal(output.total);
  }, [monthTimeLog]);

  useEffect(() => {
    workBarData(yearTimeLog);
  }, [yearTimeLog]);

  const getMonthData = () => {
    setRemoteCall("getMonthTimeLog", { user: loggedIn._id }, "", (cb) => {
      setMonthTimeLog(cb);
    });
  };
  const getYearStats = () => {
    setRemoteCall("getTeamStats", {}, "", (cb) => {
      setYearTimeLog(cb);
    });
    setRemoteCall("getCurrentYearOffsets", {}, "", (cb) => {
      setYearOffset(cb);
    });
  };

  const getLevelName = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let levelName = "Newbie";
      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        levelName = "World Class";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        levelName = "Professional";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        levelName = "Amateur";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 0) {
        levelName = "Newbie";
      }
      return levelName;
    }
    return "Newbie";
  };

  const getNexLevelPoints = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let nextLevelPoint = 50;

      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        nextLevelPoint = 200 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        nextLevelPoint = 150 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        nextLevelPoint = 100 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 0) {
        nextLevelPoint = 50 - yearTimeLog.totalPointsAveragePerUser;
      }
      return nextLevelPoint;
    }
    return 0;
  };

  const getCurrentLevelProgress = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let totalRankPoints = 0;
      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        totalRankPoints = 200;
      }
      if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        totalRankPoints = 150;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        totalRankPoints = 100;
      }
      return (totalRankPoints * 100) / 50;
    }
    return 0;
  };

  const workBarData = (data, i) => {
    const { outputDataPerMonth, total } = data;
    if (outputDataPerMonth && Object.keys(outputDataPerMonth).length > 0) {
      let dataInit = [];
      // const parent = Object.keys(data.mainCat)[i || 0]
      Object.keys(outputDataPerMonth).map((month) => {
        const thisInit = outputDataPerMonth[month];
        if (thisInit)
          dataInit.push({
            subject: thisInit.date,
            // A: Math.floor(Math.random() * 100) + 1,
            ["Kw/h"]: Number(thisInit.power).toFixed(1),
            ["co2"]: Number(thisInit.co2).toFixed(1),
            ["trees"]: Number(thisInit.trees).toFixed(1),
            ["hours"]: Number(thisInit.hours).toFixed(1),
            planned: Number(thisInit.planned).toFixed(1),
          });
      });
      setBarData(dataInit.sort((a, b) => a.subject - b.subject));
    }
  };

  const calcData = () => {
    let outputDataperDevice = [];
    let total = { hours: 0, power: 0, co2: 0, trees: 0, planned: 0 };
    monthTimeLog &&
      monthTimeLog.map((log) => {
        const start = moment(log.start);
        let plannedLog = 0;
        let end = moment();
        if (log.end) {
          end = moment(log.end);
        }
        const durationLogFull = moment.duration(end.diff(start));
        const secondFull = durationLogFull.asHours();

        //lets aggregate planned
        if (log.attr && log.attr.planned) {
          plannedLog = Number(log.attr.planned) / 60;
          plannedLog = secondFull - plannedLog;
        }
        total.hours += Number(secondFull);

        if (log.devices && log.devices.length > 0) {
          log.devices.map((device) => {
            let thisPower = device.power;
            let thisCo2 = 0;

            const thisCountryData = co2.data.find(
              (c) => loggedIn?.country === c.name
            );

            switch (device.type) {
              // case 'bus':
              //   thisCo2 = Number(device.usage) * 2.28
              //   break
              // case 'car':
              //   thisCo2 = Number(device.usage) * 3.09
              //   break
              // case 'airplane':
              //   thisCo2 = Number(device.usage) * 0.11
              //   break
              // case 'playing':
              //   //lets say its 1000 kwh
              //   thisPower = Number(device.usage) * Number(device.power || 1000)
              //   break
              default:
                thisPower = (Number(device.power) || 0) * (secondFull || 0);
                thisCo2 +=
                  thisPower * (thisCountryData?.value / 1000 || 0.31552);

                if (!outputDataperDevice[device.name])
                  outputDataperDevice[device.name] = {
                    hours: 0,
                    power: 0,
                    trees: 0,
                    co2: 0,
                    planned: 0,
                    type: device.type,
                  };
                total.planned += Number(plannedLog);
                total.power += Number(thisPower) || 0;
                total.co2 += Number(thisCo2) || 0;
                total.trees += thisCo2 * 0.00714;
                outputDataperDevice[device.name].hours += Number(secondFull);
                outputDataperDevice[device.name].power += thisPower;
                outputDataperDevice[device.name].co2 += Number(thisCo2);
                outputDataperDevice[device.name].trees += thisCo2 * 0.00714;

                break;
            }
          });
        }
      });
    return { outputDataperDevice, total };
  };

  const RenderStats = () => {
    return (
      <Flex
        width={"100%"}
        justifyContent={"space-around"}
        mb={"3em"}
        flexDirection={["column", "row"]}
        flexWrap={"wrap"}
      >
        <Box width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <ImPowerCord color={"white"} size={45} />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {yearTimeLog && (yearTimeLog?.total?.power / 1000).toFixed(1)}
                  <span>Mw/h</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        <Box dark={true} width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <FaSmog color={"white"} size={45} />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {yearTimeLog && yearTimeLog?.total?.co2.toFixed(1)}
                  <span>Kg CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        {/* <Box width={["100%"]}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <RiGamepadLine size={45} />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {yearTimeLog && yearTimeLog?.total?.hours.toFixed(2)}
                  <span>Hours</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box> */}
        <Box dark={true} width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <SiGumtree color={"white"} size={45} />
              <Box ml={".5em"} fontSize={"1.2em"} lineHeight={0.8}>
                <MetricValueWhite>
                  {yearTimeLog && yearTimeLog?.total?.trees.toFixed(1)}{" "}
                  <span>Trees</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>{" "}
        <Box dark={true} width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <MdRecycling size={45} color="white" />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {offsets
                    ? offsets
                        .reduce((acc, curr) => {
                          if (curr?.value) {
                            return acc + Number(curr.value || 0) / 1000;
                          }
                          return acc;
                        }, 0)
                        .toFixed(1)
                    : 0}
                  <span>Ton CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
      </Flex>
    );
  };

  const RenderMonthData = () => {
    return (
      <>
        <Results />
        <Box mt={"2em"} />
        {Object.keys(outputDataperDevice) &&
          Object.keys(outputDataperDevice).map((deviceKey) => {
            const thisData = outputDataperDevice[deviceKey];
            return (
              <Board
                trans={"true"}
                width="100"
                key={deviceKey}
                percentage={(thisData.hours * 100) / total.hours}
              >
                <Flex alignItems={"center"}>
                  <div>
                    {thisData.type === "console" && (
                      <Joypad fill={`white`} height={30} />
                    )}
                    {thisData.type === "tv" && (
                      <Tv height={30} width={50} fill={`white`} />
                    )}
                    {thisData.type === "computer" && (
                      <Mouse fill={`white`} height={35} />
                    )}
                  </div>
                  <MetricValueWhite>
                    {deviceKey}
                    <span>
                      {(thisData.hours || 0).toFixed(1)}h{" / "}
                      {(thisData.power || 0).toFixed(1)}kw{" "}
                    </span>
                  </MetricValueWhite>
                </Flex>
              </Board>
            );
          })}
      </>
    );
  };

  const RenderPieOffset = () => {
    const totalConsumed = yearTimeLog?.total?.co2 || 0;
    const totalOffset = offsets?.reduce((acc, curr) => {
      if (curr?.value) {
        return acc + Number(curr.value || 0);
      }
      return acc;
    }, 0);
    //get percentage of offset from total consumed co2
    let percentage = (totalOffset / totalConsumed) * 100;
    if (percentage > 100) {
      percentage = 100;
    } else if (!percentage || percentage < 0) {
      percentage = 0;
    }
    return (
      <RotatedCard
        padding="0"
        background={`linear-gradient(180deg, rgba(27, 124, 95, 0.8) 0%, rgba(27, 124, 95, 0.8) ${
          percentage > 90 ? percentage : percentage - 20
        }%, rgba(201, 55, 0, 0.8) ${percentage}%)`}
        border={"8px solid #e8fff8"}
      >
        <Flex
          width={"100%"}
          height={{ base: "30vh", sm: "140px", md: "30vh" }}
          minH={{ md: "340px" }}
          fontSize={{ base: ".8em", sm: "1em", md: "1em" }}
          flexDirection={{ base: "column", sm: "row", md: "column" }}
        >
          <Flex
            width={"100%"}
            height={{ base: "100%" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Flex alignItems={"center"} flexDir={"column"}>
              <MdRecycling size={50} color={"white"} />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValueWhite>
                  {offsets
                    ? offsets
                        .reduce((acc, curr) => {
                          if (curr?.value) {
                            return acc + Number(curr.value || 0) / 1000;
                          }
                          return acc;
                        }, 0)
                        .toFixed(1)
                    : 0}
                  <span>Ton CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Flex>
          <Flex
            width={"100%"}
            height={{ base: "100%" }}
            alignItems={"center"}
            justifyContent={"center"}
            position={"relative"}
            background={"#e8fff8"}
            fontSize={{ base: ".8em", lg: "1em" }}
          >
            <MetricValue>
              <Box opacity={".5"}>Offset</Box>{" "}
              <Box fontSize={"2.4em"}>{percentage?.toFixed(1)}%</Box>
            </MetricValue>
          </Flex>
          <Flex
            width={"100%"}
            height={{ base: "100%" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box>
              <Flex alignItems={"center"} flexDir={"column"}>
                {" "}
                <FaSmog size={50} color={"white"} />
                <Box ml={".5em"} fontSize={"1.4em"}>
                  <MetricValueWhite>
                    {yearTimeLog &&
                      ((yearTimeLog?.total?.co2 || 0) / 1000).toFixed(1)}
                    <span>Ton CO2</span>
                  </MetricValueWhite>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </RotatedCard>
    );
  };

  const ref = React.useRef(null);
  return (
    <Wrap className={`dashboard ${printing ? "printing" : ""}`} ref={ref}>
      <Header
        image={"/images/background.jpg"}
        noCard={true}
        height={"100vh"}
        minHeight={"800px"}
      ></Header>

      <Box
        position={"absolute"}
        left={"10%"}
        width={"80%"}
        top={["10vh", "10vh", "20vh", "20vh", "20vh"]}
      >
        <Box className={"wrapper"} mb={"10vh"}>
          <Box mb={"4vh"} />
          <Flex
            alignContent={"space-between"}
            width={"100%"}
            flexDirection={["column", "column", "row", "row", "row"]}
          >
            <Box
              width={["100%", "100%", "50%", "50%", "50%"]}
              padding={["0", "1em 0", "0", "0", "0"]}
              marginBottom={"2em"}
            >
              <RotatedCard>
                <Timer />
              </RotatedCard>
            </Box>
            <Box
              display={["none", "none", "block", "block", "block"]}
              width={["100%", "100%", "40%", "40%", "40%"]}
              ml={["0", "0", "5vw", "5vw", "5vw"]}
            >
              <Status outputDataperDevice={outputDataperDevice} total={total} />
              <RenderMonthData />
            </Box>
          </Flex>
          <Box
            display={["block", "block", "none", "none", "none"]}
            width={["100%", "100%", "100%", "40%", "40%"]}
            paddingBottom={["0vh", "0vh", "0%", "0%", "0%"]}
            mt={"50px"}
          >
            <Status outputDataperDevice={outputDataperDevice} total={total} />
            <Box mt={{ base: "2em", md: "0" }} />
            <RenderMonthData />
          </Box>

          <Flex
            width={["100%"]}
            mt={"2em"}
            justifyContent={"space-between"}
            flexDir={{ base: "column", md: "row" }}
          >
            <Box
              width={{ base: "100%", md: "65%" }}
              mt={"2em"}
              display={["block"]}
            >
              <Board margin={"0 1em 1em 0"} width={"100%"}>
                <Flex alignItems={"center"} width={"100%"}>
                  <RiGamepadLine color={colors.secondary[500]} size={45} />
                  <Box ml={".5em"} fontSize={"1.1em"} width={"100%"}>
                    <MetricValue>
                      {getLevelName()}
                      <span>
                        {(yearTimeLog?.totalPointsAveragePerUser || 0).toFixed(
                          1
                        )}{" "}
                        team pts
                      </span>
                      <Tooltip
                        label={`Next level reached in ${(
                          getNexLevelPoints() || 0
                        ).toFixed(1)} points`}
                      >
                        <Box>
                          <Progress
                            mt={"0.2em"}
                            value={getCurrentLevelProgress()}
                            borderRadius={"1em"}
                            colorScheme={"red"}
                          />
                        </Box>
                      </Tooltip>
                    </MetricValue>
                  </Box>
                </Flex>
              </Board>
              <Box width={"100%"}>
                <RenderStats />
              </Box>
            </Box>
            <Box
              width={{ base: "100%", md: "30%" }}
              mt={{ base: 0 }}
              display={["block"]}
            >
              <RenderPieOffset />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Wrap>
  );
}
