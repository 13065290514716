import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Input,
  Link,
  Heading,
  Select,
  useToast,
  CheckboxGroup,
  Checkbox
} from "@chakra-ui/react";
import { setGlobalState } from "../store";
import co2 from "../co2";
function Login(props) {
  const { teamId } = props.match?.params;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [client, setClient] = useState({});
  // const [sectors, setSectors] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const toast = useToast();
  setGlobalState("pageClass", "login");

  useEffect(() => {
    const getClientData = async () => {
      const client = await axios.get(`/auth/client/${teamId}`);
      setClient(client.data);
      setCompanyName(client.data.name);
      console.log(client);
    };
    if (teamId) getClientData();
    return () => {};
  }, [teamId]);

  const registerUser = async e => {
    e.preventDefault();
    if (
      lastName === "" ||
      firstName === "" ||
      email === "" ||
      country === "" ||
      password === ""
    ) {
      return toast({
        title: "Please enter all fields",
        status: "warning",
        position: "bottom-left"
      });
    }

    if (selectedTerms.length === 0) {
      return toast({
        title:
          "Please read and accept the terms and conditions and the privacy policy",
        status: "warning",
        position: "bottom-left"
      });
    }

    try {
      const response = await axios.post(`/auth/register`, {
        firstName,
        lastName,
        email,
        password,
        country,
        companyName
        // sectors: selectedSectors
      });
      if (response.data) {
        if (response.data && response.data.status === "OK") {
          props.history.push(teamId ? `/${teamId}` : "/");
        } else {
          toast({
            title: response.data.msg
              ? response.data.msg.message
              : "Verifique os seus dados",
            status: "warning",
            position: "bottom-left"
          });
        }
      }
    } catch (e) {
      console.log(e);
      toast({
        title: e || "Verifique os seus dados",
        status: "warning",
        position: "bottom-left"
      });
    }
  };

  return (
    <Flex
      className="login-bg"
      align="center"
      alignItems="center"
      justifyContent={["flex-start"]}
      flexDirection={["column", "column", "row", "row", "row"]}
      height="100vh"
      w={"100%"}
      backgroundImage={`radial-gradient(circle at center left,
        ${
          client?.theme?.primary
            ? `${client?.theme?.primary}90`
            : "rgba(15, 76, 58, 0.9) 30%"
        },
        ${
          client?.theme?.primary
            ? `${client?.theme?.primary}40`
            : "rgba(15, 76, 58, 0.4) 100%"
        }
      ), 
      url("/images/home.jpg")`}
    >
      <Box
        className={"loginwrap fadeinUp"}
        width={["100%", "100%", "45%", "45%", "45%"]}
        maxWidth={["550px"]}
        borderWidth="0"
        order={["2", "2", "1", "1", "1"]}
      >
        <div className={"login-container"}>
          <div>
            <Box textAlign={"center"}>
              <Heading
                as="h3"
                fontSize={["1.5rem", "2rem", "2.5rem", "2.5rem"]}
                lineHeight={"1"}
                color={"white"}
              >
                {client.name ? client.name : "Create a new Account"}
              </Heading>
              <Heading
                as="h3"
                fontSize={["0.9rem", "1.0rem", "1.2rem", "1.5rem", "1.5rem"]}
                color={"#ffffff70"}
                mb={"1em"}
              >
                {client._id
                  ? `New account for team`
                  : "please add your details"}
              </Heading>
            </Box>
            <form
              className="registration-form"
              onSubmit={registerUser}
              data-login-form
            >
              <Flex width={"100%"} mb={".5em"} justifyContent={"space-between"}>
                <Input
                  width={"47%"}
                  data-login-email
                  onChange={e => setFirstName(e.target.value)}
                  placeholder="First Name"
                  colorScheme="primary"
                  size="lg"
                />
                <Input
                  width={"47%"}
                  data-login-email
                  onChange={e => setLastName(e.target.value)}
                  placeholder="Last Name"
                  colorScheme="primary"
                  size="lg"
                />
              </Flex>
              <Input
                data-login-email
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                colorScheme="primary"
                size="lg"
              />
              <Input
                type="password"
                data-login-password
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
                colorScheme="primary"
                size="lg"
              />

              <Select
                border={0}
                m={".5em 0"}
                onChange={e => setCountry(e.target.value)}
                // onChange={v => setCountry(v.target.value)}
              >
                <option value={""}>Country</option>
                {co2?.data?.map(c => {
                  return <option value={c.name}>{c.name}</option>;
                })}
              </Select>
              <CheckboxGroup
                onChange={e => setSelectedTerms(e)}
                colorScheme="primary"
                defaultValue={selectedTerms}
              >
                <Flex
                  width={"100%"}
                  margin={"1em "}
                  color={"white"}
                  justifyContent={"space-between"}
                >
                  <Checkbox value={"accept"}>
                    I accept the{" "}
                    <a
                      href="https://www.gamers4theplanet.org/Terms"
                      target={"_blank"}
                    >
                      terms and coditions and privacy policy
                    </a>
                  </Checkbox>
                </Flex>
              </CheckboxGroup>

              <Button
                onClick={e => registerUser(e)}
                colorScheme="primary"
                size="lg"
                value="Submit!"
                width={"100%"}
                _hover={{
                  background: "primary.50",
                  color: "primary.500"
                }}
              >
                Register
              </Button>
            </form>
            <Link
              href={teamId ? `/login/${teamId}` : "/"}
              fontSize={".8em"}
              margin={"1em"}
              display={"block"}
              color={"white"}
              textAlign={"center"}
              _hover={{
                color: "primary.50"
              }}
            >
              Login
            </Link>
          </div>
        </div>
      </Box>

      <Box
        marginLeft={["0%", "0%", "0%", "0%", "0%"]}
        marginTop={["0", "0", "0%", "0%", "0%"]}
        width={["100%", "100%", "55%", "55%", "55%"]}
        order={["1", "1", "2", "2", "2"]}
        minHeight={{ base: "40vh", md: "300px" }}
        className={"loginLogo"}
      >
        {/* <Heading
          as="h3"
          fontSize={['0.9rem', '1.0rem', '1.2rem', '1.5rem', '1.5rem']}
          color={'white'}
        >
          Assessment application
        </Heading>
        <Heading
          as="h1"
          fontSize={['2.8rem', '4rem', '5rem', '7rem', '7rem']}
          color={'white'}
        >
          Beyond The Obvious
        </Heading> */}
      </Box>
    </Flex>
  );
}

export default withRouter(Login);
