import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from "@chakra-ui/react";
import { setRemoteCall } from "../remote";
import { getGlobalState } from "../store";

export default function EditTypes(props) {
  // const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const types = getGlobalState("userTypes");
  const [typeText, setTypeText] = useState("");
  const [typeEditId, setTypeEditId] = useState("");
  const [typeEditText, setTypeEditText] = useState("");

  /**
   * Del Type
   */
  const delSector = type => {
    setRemoteCall("delUserTypes", type, null);
  };
  /**
   * Set Type
   */
  const setType = type => {
    let typeObject = {
      name: type,
      date: new Date(),
      attr: {},
      status: 1
    };
    if (typeEditId) {
      typeObject._id = typeEditId;
    }
    console.log(typeObject);
    setRemoteCall("setUserTypes", typeObject, null);
  };

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0">Edit User Types</DrawerHeader>

        <DrawerBody overflow={"auto"}>
          <InputGroup size="lg">
            <Input
              type={"text"}
              value={typeText}
              onChange={e => setTypeText(e.target.value)}
              placeholder="Enter new Sector"
            />
            <InputRightElement width="4.5rem">
              <Button
                size="sm"
                colorScheme="primary"
                marginTop={".7rem"}
                onClick={() => {
                  setTypeEditId(null);
                  setType(typeText);
                  setTypeText("");
                }}
              >
                Add +
              </Button>
            </InputRightElement>
          </InputGroup>

          {types &&
            types.map((init, i) => {
              return (
                <Flex
                  key={i}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Input
                    onFocus={() => {
                      setTypeEditId(init._id);
                      setTypeEditText(init.name);
                    }}
                    onChange={e => setTypeEditText(e.target.value)}
                    value={typeEditId === init._id ? typeEditText : init.name}
                  />
                  <Flex>
                    <Button
                      size="sm"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() => {
                        setType(typeEditText);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() => delSector(init._id)}
                    >
                      -
                    </Button>
                  </Flex>
                </Flex>
              );
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="0">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
