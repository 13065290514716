import styled from 'styled-components'
import { colors } from '../theme'

export const Board = styled.div`
  && {
    width: 32%;
    border: none;
    cursor: pointer;
    background: #fff;
    padding: 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0.5em 0;
    }
  }
`
export const Row = styled.div`
  && {
    display: flex;
    margin: 2em 0;
  }
`
export const RowDashPage = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: -20vh 0 0 0;
  }
`
