import styled from "styled-components";
import { colors } from "../theme";

export const PlayerValue = styled.div`
  && {
    font-size: 1.2em;
    font-weight: bold;
    /* font-style: italic; */
    line-height: 1;
    margin: 0.5em 0;
    span {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      display: block;
      opacity: 0.6;
      font-size: 0.8em;
      line-height: 1.2;
      a {
        text-decoration: underline;
      }
    }
  }
`;
export const DayValue = styled.div`
  && {
    text-align: center;
    font-size: 1.6em;
    font-weight: bold;
    /* font-style: italic; */
    line-height: 1;
    margin: 0.5em 0;
    span {
      display: block;
      opacity: 0.6;
      font-size: 0.7em;
    }
  }
`;
export const DeviceName = styled.div`
  && {
    color: #fff;
    font-size: 1.4em;
    font-weight: bold;
    /* font-style: italic; */
    line-height: 1;
    margin-left: 0.6em;
    span {
      opacity: 0.6;
      font-size: 0.7em;
    }
  }
`;
export const PlayerName = styled.div`
  && {
    transition: all 0.5s ease-in;
    padding: 0 1em;
    font-size: 4em;
    line-height: 1;
    color: #fff;
    /* font-style: italic; */
    font-weight: bold;
  }
`;
export const PlayerImage = styled.div`
  && {
    transition: all 0.5s ease-in;
    height: ${props => (props.height ? `${props.height}vh` : `30vh`)};
    background: ${colors.secondary[500]} no-repeat;
    ${props =>
      props.image
        ? `background-image: linear-gradient(
                  to bottom,
                  ${colors.primary.transparent[40]},
                  40%,
                  ${colors.primary.transparent[10]}
                ), url(${props.image});`
        : ""}
    background-position: center;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  }
`;
export const Row = styled.div`
  && {
    display: flex;
    margin: 2em 0;
  }
`;
export const RowDashPage = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;
export const CommentNumber = styled.div`
  && {
    position: relative;
    font-weight: bold;
    font-size: 1em;
    line-height: 24px;
    width: 100%;
    max-width: 35px;
    background: #fafafa;
    border-radius: 15px;
    padding: 5px;
    text-align: center;
    color: #000;

    &:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 7px solid #fafafa;
      border-right: 6px solid transparent;
      border-top: 6px solid #fafafa;
      border-bottom: 7px solid transparent;
      left: 6px;
      bottom: -7px;
    }
  }
`;
export const RequestName = styled.div`
  && {
    color: #fff;
    font-size: 1.4em;
    font-weight: bold;
    /* font-style: italic; */
    line-height: 1;
    margin-left: 0.6em;
    span {
      opacity: 0.6;
      font-size: 0.7em;
    }
  }
`;

export const DayRange = styled.div`
  && {
    background: #edf2f7;
    display: flex;
    font-size: 0.9em;
    border-radius: 6px;
    padding: 0.3em 0 0.3em 1em;
    margin: 0.2em 0;
    align-items: center;
    justify-content: space-between;
  }
`;
