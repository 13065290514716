import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { FaUserCircle } from "react-icons/fa";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from "@chakra-ui/react";
import { Board } from "../components/styled";
import { getGlobalState } from "../store";
import { PlayerValue } from "./styled";

export default function ChooseUserModal(props) {
  // const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const types = getGlobalState("userTypes");
  const loggedIn = getGlobalState("loggedIn"); //dont remove
  const users = getGlobalState("users"); //dont remove

  console.log(loggedIn);
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0">Select User</DrawerHeader>

        <DrawerBody overflow={"auto"}>
          {users &&
            users.map((user, i) => {
              if (loggedIn._id === user._id) return null;
              let thisClient;
              if (user.role) {
                thisClient = types.find(c => c._id === user.role) || "global";
              }

              const isActive = props.selectedUsers.find(u => u === user._id);
              return (
                <Board
                  onClick={() =>
                    !props.isRunning &&
                    props.setSelectedUsers(
                      isActive
                        ? props.selectedUsers.filter(u => u !== user._id)
                        : [...props.selectedUsers, user._id]
                    )
                  }
                  active={isActive}
                  width={"100%"}
                  key={i}
                >
                  <Flex
                    alignContent={"center"}
                    className={"content"}
                    alignItems={"center"}
                  >
                    <Box marginRight={"1em"}>
                      {user.image ? (
                        <Box
                          borderRadius={"50%"}
                          height={"3em"}
                          width={"3em"}
                          background={`url(${user.image})`}
                          backgroundPosition={"center"}
                          backgroundSize={"cover"}
                        />
                      ) : (
                        <Icon
                          as={FaUserCircle}
                          color={isActive ? "white" : ""}
                          height={"3em"}
                          width={"3em"}
                        />
                      )}
                    </Box>
                    <Box color={isActive ? "white" : ""}>
                      <PlayerValue>
                        {user.firstName} {user.lastName}
                        <span>{user.status ? "Active" : "Inactive"} </span>
                      </PlayerValue>
                    </Box>
                  </Flex>
                </Board>
              );
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="0">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
