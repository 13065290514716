import React from "react";
import {
  Input,
  Select,
  Button,
  Flex,
  ButtonGroup,
  Box,
  InputGroup,
  Textarea
} from "@chakra-ui/react";
import { Board } from "../components/styled";
import moment from "moment";
import { getGlobalState } from "../store";
import { FaBus, FaCar } from "react-icons/fa";
import { MdTrain } from "react-icons/md";
import { ImAirplane } from "react-icons/im";
import { IoGameController, IoRemoveCircleSharp } from "react-icons/io5";
const EditTravels = ({ i, handleFormFieldChange, devices, details }) => {
  const loggedIn = getGlobalState("loggedIn"); //dont remove

  /*
  type: null, // car, bus, airplane, playing
      name: '',
      usage: '', // km, hrs
      id: null
  */

  const addNewDevice = () => {
    const allDevices = [
      ...(devices || []),
      {
        type: "car", // car, bus, airplane, playing
        people: "",
        name: "",
        usage: "", // km, hrs
        id: devices ? devices.length : 0
      }
    ];
    handleFormFieldChange("devices", { target: { value: allDevices } });
  };
  const removeDevice = i => {
    const newDevices = devices.filter((d, a) => d.id !== i);
    handleFormFieldChange("devices", {
      target: { value: newDevices }
    });
  };

  const handleDeviceDataChange = (key, { target: { value } }, id) => {
    const allDevices = devices.map((device, a) => {
      if (device.id === id) {
        return { ...device, [key]: value };
      }
      return device;
    });
    handleFormFieldChange("devices", { target: { value: allDevices } });
  };
  return (
    <>
      {/* <Input
        padding={'.5em .5em'}
        height={'3em'}
        onChange={event => handleFormFieldChange('task', event)}
        type={'text'}
        placeholder="Task Name"
        value={details.task}
      /> */}

      <InputGroup key={i} margin={"1em 0"} size="sm">
        <Textarea
          padding={"1.5em .5em"}
          onChange={event => handleFormFieldChange("details", event)}
          placeholder={"Details"}
          value={details.details}
        />
      </InputGroup>
      <Flex alignItems={"center"}>
        <ButtonGroup width={"100%"} size="md" isAttached>
          <Input
            padding={".5em .5em"}
            height={"3em"}
            onChange={event => handleFormFieldChange("start", event)}
            type={"datetime-local"}
            value={moment(details.start || new Date()).format(
              "YYYY-MM-DDTHH:mm"
            )}
          />
          <Input
            padding={".5em .5em"}
            height={"3em"}
            onChange={event => handleFormFieldChange("end", event)}
            type={"datetime-local"}
            value={moment(details.end || new Date()).format("YYYY-MM-DDTHH:mm")}
          />
        </ButtonGroup>
      </Flex>

      <Flex alignItems={"center"}>
        <Select
          placeholder="Select Status"
          value={details.status}
          onChange={e => handleFormFieldChange("status", e)}
        >
          <option value={0}>Finished</option>
          <option value={1}>Ongoing</option>
        </Select>
      </Flex>

      {loggedIn && loggedIn.admin && (
        <Flex justifyContent={"flex-end"} padding={"1em 0"}>
          <Button size={"sm"} height={"2em"} onClick={() => addNewDevice()}>
            + add item
          </Button>
        </Flex>
      )}

      {details.devices &&
        details.devices.map((device, i) => (
          <Board
            width={"100%"}
            key={i}
            active={true}
            borderBottom={"1px solid #292727"}
            paddingBottom={".5em"}
            mb={".5em"}
          >
            <Flex alignItems={"center"} position={"relative"}>
              <Flex width={"55%"}>
                <Input
                  width={"45%"}
                  padding={".5em .5em"}
                  height={"3em"}
                  onChange={event =>
                    handleDeviceDataChange("name", event, device.id)
                  }
                  type={"text"}
                  placeholder="Name"
                  value={device.name}
                  borderRadius={".5em 0 0 .5em"}
                  mr={"1px"}
                />
                {device.type !== "playing" && (
                  <Input
                    width={"45%"}
                    padding={".5em .5em"}
                    height={"3em"}
                    onChange={event =>
                      handleDeviceDataChange("usage", event, device.id)
                    }
                    type={"number"}
                    placeholder={
                      device.type === "car" ||
                      device.type === "bus" ||
                      device.type === "airplane"
                        ? "km"
                        : "Hours"
                    }
                    value={device.usage}
                    mr={"1px"}
                    borderRadius={"0 0"}
                  />
                )}
                {device.type === "playing" && (
                  <>
                    <Input
                      width={"45%"}
                      padding={".5em .5em"}
                      height={"3em"}
                      onChange={event =>
                        handleDeviceDataChange("usage", event, device.id)
                      }
                      type={"number"}
                      placeholder={"Hours"}
                      value={device.usage}
                      borderRadius={"0"}
                    />
                    <Input
                      width={"45%"}
                      padding={".5em .5em"}
                      height={"3em"}
                      onChange={event =>
                        handleDeviceDataChange("power", event, device.id)
                      }
                      type={"number"}
                      placeholder={"Kw/h"}
                      value={device.power}
                      borderRadius={"0 .5em .5em 0"}
                    />
                  </>
                )}

                {(device.type === "car" ||
                  device.type === "bus" ||
                  device.type === "train" ||
                  device.type === "airplane") && (
                  <Input
                    width={"45%"}
                    padding={".5em .5em"}
                    height={"3em"}
                    onChange={event =>
                      handleDeviceDataChange("people", event, device.id)
                    }
                    type={"number"}
                    placeholder={"People"}
                    value={device.people}
                    borderRadius={"0 .5em .5em 0"}
                  />
                )}
              </Flex>
              <ButtonGroup width={"30%"} marginLeft={"2%"} size="md" isAttached>
                <Button
                  padding={".3em"}
                  colorScheme={
                    device.type === "train" ? "secondary" : "primary"
                  }
                  variant={device.type === "train" ? "solid" : "outline"}
                  onClick={() =>
                    handleDeviceDataChange(
                      "type",
                      {
                        target: { value: "train" }
                      },
                      i
                    )
                  }
                >
                  <MdTrain fill={"#fff"} height={20} width={20} />
                </Button>
                <Button
                  padding={".3em"}
                  colorScheme={device.type === "bus" ? "secondary" : "primary"}
                  variant={device.type === "bus" ? "solid" : "outline"}
                  onClick={() =>
                    handleDeviceDataChange(
                      "type",
                      {
                        target: { value: "bus" }
                      },
                      i
                    )
                  }
                >
                  <FaBus fill={"#fff"} height={20} width={20} />
                </Button>
                <Button
                  padding={".3em"}
                  colorScheme={device.type === "car" ? "secondary" : "primary"}
                  variant={device.type === "car" ? "solid" : "outline"}
                  onClick={() =>
                    handleDeviceDataChange(
                      "type",
                      {
                        target: { value: "car" }
                      },
                      i
                    )
                  }
                >
                  <FaCar fill={"#fff"} height={20} width={20} />
                </Button>
                <Button
                  padding={".3em"}
                  colorScheme={
                    device.type === "airplane" ? "secondary" : "primary"
                  }
                  variant={device.type === "airplane" ? "solid" : "outline"}
                  onClick={() =>
                    handleDeviceDataChange(
                      "type",
                      { target: { value: "airplane" } },
                      i
                    )
                  }
                >
                  <ImAirplane fill={"#fff"} height={20} width={20} />
                </Button>
                <Button
                  padding={".3em"}
                  colorScheme={
                    device.type === "playing" ? "secondary" : "primary"
                  }
                  variant={device.type === "playing" ? "solid" : "outline"}
                  onClick={() =>
                    handleDeviceDataChange(
                      "type",
                      { target: { value: "playing" } },
                      i
                    )
                  }
                >
                  <IoGameController fill={"#fff"} height={20} width={20} />
                </Button>
              </ButtonGroup>
              <Box
                position={"absolute"}
                top={"-25px"}
                right={0}
                cursor={"pointer"}
                onClick={() => removeDevice(device.id)}
              >
                <IoRemoveCircleSharp size={"2em"} />
              </Box>
            </Flex>
          </Board>
        ))}
    </>
  );
};

export default EditTravels;
