import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { configAxios } from "./util";
// import { initNotification } from "./notifications";

import "react-toastify/dist/ReactToastify.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from "./app/App";
configAxios();

// initNotification();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: registration => {
    console.info("service worker on success state");
    console.log(registration);
  }
});
