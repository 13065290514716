import * as React from 'react'

function Joypad(props) {
  return (
    <svg
      width={53}
      height={38}
      viewBox="0 0 53 38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'Group'}</title>
      <g fill={props.fill} fillRule="nonzero" opacity={0.9}>
        <path d="M42.091 4.123V2.031c0-.918-.746-1.664-1.664-1.664h-4.992c-.917 0-1.664.746-1.664 1.664v1.86c-.996.23-1.872.631-2.724 1.026-.714.33-1.444.665-2.268.91h-4.991c-.824-.245-1.554-.58-2.267-.91-.853-.396-1.728-.796-2.725-1.026v-1.86c0-.918-.746-1.664-1.664-1.664H12.14c-.918 0-1.664.746-1.664 1.664v2.09c-2.056 1-4.226 3.741-6.06 7.76C1.885 17.423.492 24.27.492 31.16c0 4.48 1.36 6.657 4.16 6.657 4.027 0 9.308-3.844 13.029-7.069a8.59 8.59 0 015.63-2.086h5.943c2.08 0 4.079.741 5.63 2.086 3.72 3.225 9.002 7.07 13.03 7.07 2.799 0 4.16-2.179 4.16-6.659 0-12.998-5.21-24.707-9.984-27.037zm-6.656-2.092h4.992v1.665h-4.992V2.03zm-23.295 0h4.992v1.665H12.14V2.03zm35.775 34.123c-3.7 0-9.07-4.175-11.94-6.662a10.248 10.248 0 00-6.72-2.493h-5.943c-2.479 0-4.866.885-6.72 2.493-2.87 2.487-8.239 6.662-11.94 6.662-.81 0-2.496 0-2.496-4.994 0-14.905 6.566-25.8 9.984-25.8h4.992c1.388 0 2.454.495 3.689 1.067 1.445.67 3.083 1.43 5.463 1.43 2.379 0 4.017-.76 5.462-1.43 1.235-.572 2.302-1.067 3.69-1.067h4.991c3.418 0 9.984 10.895 9.984 25.8 0 4.994-1.686 4.994-2.496 4.994z" />
        <path d="M37.931 14.515a3.332 3.332 0 003.328-3.329 3.332 3.332 0 00-3.328-3.329 3.332 3.332 0 00-3.328 3.329 3.332 3.332 0 003.328 3.33zm0-4.993c.918 0 1.664.746 1.664 1.664 0 .918-.746 1.665-1.664 1.665a1.666 1.666 0 01-1.664-1.665c0-.918.746-1.664 1.664-1.664zM37.931 19.509a3.332 3.332 0 00-3.328 3.329 3.332 3.332 0 003.328 3.329 3.332 3.332 0 003.328-3.33 3.332 3.332 0 00-3.328-3.328zm0 4.993a1.666 1.666 0 01-1.664-1.664c0-.918.746-1.665 1.664-1.665a1.666 1.666 0 010 3.329zM32.107 13.683a3.332 3.332 0 00-3.328 3.329 3.332 3.332 0 003.328 3.329 3.332 3.332 0 003.328-3.33 3.332 3.332 0 00-3.328-3.328zm0 4.993a1.666 1.666 0 01-1.664-1.664c0-.918.747-1.665 1.664-1.665a1.666 1.666 0 010 3.329zM17.065 9.053l-.06-.364h-4.738l-.06.364a8.355 8.355 0 00-5.527 5.529l-.364.06v4.74l.364.06a8.355 8.355 0 005.527 5.529l.06.363h4.738l.06-.363a8.355 8.355 0 005.527-5.529l.364-.06v-4.74l-.364-.06a8.357 8.357 0 00-5.527-5.53zm4.148 8.932l-4.804.8-.8 4.806c-.32.046-.642.079-.973.079-.331 0-.654-.033-.973-.08l-.8-4.805-4.804-.8a6.702 6.702 0 01-.079-.973c0-.331.033-.654.08-.973l4.803-.8.8-4.806c.32-.047.642-.08.973-.08.331 0 .654.033.973.08l.8 4.805 4.804.8c.046.32.079.643.079.974 0 .331-.033.654-.08.973zm-.521-3.72l-2.837-.473-.473-2.838a6.69 6.69 0 013.31 3.31zm-8.802-3.311l-.473 2.838-2.837.473a6.69 6.69 0 013.31-3.311zm-3.31 8.805l2.837.473.473 2.838a6.69 6.69 0 01-3.31-3.31zm8.802 3.31l.473-2.837 2.837-.473a6.69 6.69 0 01-3.31 3.31z" />
      </g>
    </svg>
  )
}

export default Joypad
