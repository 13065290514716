import * as React from 'react'

function Tv(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 512 512"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M496.964 73.72H15.036C6.732 73.72 0 80.452 0 88.756v276.892c0 8.304 6.732 15.036 15.036 15.036H202.98v27.523h-40.564c-8.304 0-15.036 6.732-15.036 15.036s6.732 15.036 15.036 15.036h187.167c8.304 0 15.036-6.732 15.036-15.036s-6.732-15.036-15.036-15.036H309.02v-27.523h187.944c8.304 0 15.036-6.732 15.036-15.036V88.756c0-8.304-6.732-15.036-15.036-15.036zM278.948 408.208h-45.896v-27.523h45.896zm202.98-57.595H30.072v-246.82h451.856z" />
    </svg>
  )
}

export default Tv
