import * as React from 'react'

function Mouse(props) {
  return (
    <svg
      width={45}
      height={70}
      viewBox="0 0 45 70"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'Shape'}</title>
      <path
        d="M40.5 19.9v-4.88a5.652 5.652 0 00-2.835-4.895l-5.866-3.36H27v-4.51h-3.375V0h-2.25v2.255H18v4.51H13.201l-5.866 3.36A5.655 5.655 0 004.5 15.019v12.902L0 36.941V48.8l8.075 13.243c2.957 4.85 8.316 7.863 13.989 7.863 6.249 0 11.867-3.48 14.662-9.082L45 44.239V25.537l-4.5-5.638zM20.991 34.952l-.964-2.256h4.946l-.964 2.255H20.99zm4.946-4.51h-6.874l-.964-2.256h8.802l-.964 2.255zM27 25.932h-9v-2.256h9v2.256zm-7.875-9.02H18v-2.255h1.125v2.255zm0 2.255v2.255H18v-2.255h1.125zm2.25 2.255v-9.02h2.25v9.02h-2.25zm5.625 0h-1.125v-2.255H27v2.255zm0-4.51h-1.125v-2.255H27v2.255zm0-4.51h-1.125v-2.255h-6.75v2.255H18V9.02h9v3.383zM20.25 4.51h4.5v2.255h-4.5V4.51zM6.75 15.02a3.39 3.39 0 011.701-2.938l5.348-3.062h1.951v19.399l3.759 8.789h1.866v11.275h2.25V37.208h1.866l3.759-8.79V9.02h1.95l5.349 3.062a3.394 3.394 0 011.701 2.937v25.162l-7.388 8.887a10.844 10.844 0 01-7.237 3.862v-2.193h-2.25v2.193a10.841 10.841 0 01-7.237-3.863L6.75 40.182V15.019zm-4.5 22.454l2.25-4.51v18.89l-2.25-3.688V37.474zm32.463 22.34c-2.41 4.833-7.257 7.836-12.65 7.836-4.893 0-9.518-2.599-12.068-6.784L6.75 55.544v-11.84l5.659 6.806A13.087 13.087 0 0022.5 55.248a13.09 13.09 0 0010.091-4.737l5.659-6.807v9.021l-3.537 7.089zm8.037-16.108l-2.25 4.51V23.51l2.25 2.818v17.378z"
        fill={props.fill}
        fillRule="nonzero"
        opacity={0.9}
      />
    </svg>
  )
}

export default Mouse
