import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { configAxios } from "../util";
import { withRouter } from "react-router-dom";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import { setGlobalState, setStorageState } from "../store";
import "./Login.css";
function Login(props) {
  const { teamId } = props.match?.params;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [client, setClient] = useState({});
  const toast = useToast();
  setGlobalState("pageClass", "login");
  const [animationHeaderParent] = useAutoAnimate();
  const [animationFormParent] = useAutoAnimate({
    // Animation duration in milliseconds (default: 250)
    duration: 250,
    // Easing for motion (default: 'ease-in-out')
    easing: "ease-in-out",
    // When true, this will enable animations even if the user has indicated
    // they don’t want them via prefers-reduced-motion.
    disrespectUserMotionPreference: false,
  });

  useEffect(() => {
    const getClientData = async () => {
      const client = await axios.get(`/auth/client/${teamId}`);
      setClient(client.data);
    };
    if (teamId) getClientData();
    return () => {};
  }, [teamId]);

  const resetPassword = async (e) => {
    if (!username || username === "") {
      toast({
        title: "Please enter your email in the field first",
        status: "warning",
        position: "bottom-left",
      });
    }

    await axios.post(`/auth/forgot`, {
      email: username,
    });
    toast({
      title: "Check your email",
      status: "warning",
      position: "bottom-left",
    });
  };

  const loginUser = async (e) => {
    e.preventDefault();

    if (username === "" || password === "") {
      return toast({
        title: "Please check your email and password",
        status: "warning",
        position: "bottom-left",
      });
    }

    try {
      const response = await axios.post(`/auth`, {
        email: username,
        password,
      });
      if (response.data && response.data.token) {
        await setStorageState("loggedIn", response.data);
        configAxios();
        props.history.push("/dashboard");
      } else {
        toast({
          title: "Can't Login, please verify you details",
          status: "warning",
          position: "bottom-left",
        });
      }
    } catch (e) {
      console.log(e);
      setStorageState("loggedIn", null);
      toast({
        title: "Can't Login, please verify you details",
        status: "warning",
        position: "bottom-left",
      });
    }
  };

  return (
    <Flex
      className="login-bg"
      align="center"
      alignItems="center"
      justifyContent={["flex-start"]}
      flexDirection={["column", "column", "row", "row", "row"]}
      height="100vh"
      w={"100%"}
      backgroundImage={`radial-gradient(circle at center left,
        ${
          client?.theme?.primary
            ? `${client?.theme?.primary}90`
            : "rgba(15, 76, 58, 0.9) 30%"
        },
        ${
          client?.theme?.primary
            ? `${client?.theme?.primary}40`
            : "rgba(15, 76, 58, 0.4) 100%"
        }
      ), 
      url("/images/home.jpg")`}
    >
      <Box
        className={"loginwrap fadeinUp"}
        width={["100%", "100%", "45%", "45%", "45%"]}
        maxWidth={["550px"]}
        borderWidth="0"
        order={["2", "2", "1", "1", "1"]}
      >
        <div className={"login-container"} ref={animationHeaderParent}>
          <div>
            <Box textAlign={"center"}>
              <Heading
                as="h3"
                fontSize={["1.5rem", "2rem", "2.5rem", "2.5rem"]}
                lineHeight={"1"}
                color={"white"}
              >
                {teamId ? client.name : "Access your Account"}
              </Heading>
              <Heading
                as="h3"
                fontSize={["0.9rem", "1.0rem", "1.2rem", "1.5rem", "1.5rem"]}
                color={"#ffffff70"}
                mb={"1em"}
              >
                use your credentials to login
                {/* for internal use only - Betclic Apogee property */}
              </Heading>
            </Box>
            {/* <Box
              backgroundImage={"url(/images/logo.png)"}
              backgroundSize={"contain"}
              backgroundPosition={"center"}
              backgroundRepeat={"no-repeat"}
              height={["5em", "5em", "6em", "6em", "6em"]}
              margin={["1em 0", "2em 0", "3em 0", "3em 0", "3em 0"]}
            /> */}
            <form
              className="login-form "
              onSubmit={loginUser}
              data-login-form
              ref={animationFormParent}
            >
              <Input
                data-login-email
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Email"
                colorScheme="primary"
                size="lg"
              />
              <Input
                type="password"
                data-login-password
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                colorScheme="primary"
                size="lg"
              />

              <Button
                onClick={(e) => loginUser(e)}
                colorScheme="primary"
                size="lg"
                value="Submit!"
                width={"100%"}
                _hover={{
                  background: "primary.50",
                  color: "primary.500",
                }}
              >
                Login
              </Button>
            </form>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
            >
              <Link
                href={teamId ? `/register/${teamId}` : "/register"}
                fontSize={"1em"}
                margin={"1em"}
                color={"white"}
                textAlign={"center"}
                _hover={{
                  color: "primary.50",
                }}
              >
                Register
              </Link>
              <Link
                href={"#"}
                fontSize={"1em"}
                margin={"1em"}
                color={"white"}
                textAlign={"center"}
                onClick={() => resetPassword()}
                _hover={{
                  color: "primary.50",
                }}
              >
                Reset Password
              </Link>
            </Flex>
          </div>
        </div>
      </Box>

      <Box
        marginLeft={["0%", "0%", "0%", "0%", "0%"]}
        marginTop={["0", "0", "0%", "0%", "0%"]}
        width={["100%", "100%", "55%", "55%", "55%"]}
        order={["1", "1", "2", "2", "2"]}
        minHeight={{ base: "40vh", md: "300px" }}
        className={"loginLogo"}
      >
        {/* <Heading
          as="h3"
          fontSize={["0.9rem", "1.0rem", "1.2rem", "1.5rem", "1.5rem"]}
          color={"white"}
        >
          A SMALL STEP FOR MAN,
        </Heading>
        <Heading
          as="h1"
          fontSize={["2.8rem", "4rem", "5rem", "8rem", "8rem"]}
          color={"white"}
          lineHeight={1}
        >
          A GIANT LEAP FOR GAMING!
        </Heading> */}
      </Box>
    </Flex>
  );
}

export default withRouter(Login);
