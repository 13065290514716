import React from 'react'

const Dashboard = props => {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'clock-circle'}</title>
      <path
        d="M21.062.354c11.319 0 20.5 9.18 20.5 20.5 0 11.319-9.181 20.5-20.5 20.5-11.32 0-20.5-9.181-20.5-20.5 0-11.32 9.18-20.5 20.5-20.5zm1.328 7.715h-2.651a.442.442 0 00-.44.44v15.172a.437.437 0 00.181.358l9.115 6.646a.44.44 0 00.617-.094l.18-.244a.466.466 0 00.448-.168l1.634-2.082a.403.403 0 00-.102-.593l-8.148-5.506V8.781c0-.224-.186-.408-.42-.426a.444.444 0 00-.414-.286z"
        fill={props.fill || '#C5C5C5'}
        fillRule="nonzero"
      />
    </svg>
  )
}
export default Dashboard
