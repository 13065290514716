import React, { useState } from "react";
import { Button } from "@chakra-ui/react";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton
} from "@chakra-ui/react";
import { getGlobalState } from "../store";
import { setRemoteCall } from "../remote";
import RenderEditTimelog from "../users/Tabs/EditTimelog";
import EditTravels from "./EditTravels";

export default function Edit(props) {
  // const types = getGlobalState('userTypes')
  const [details, setDetails] = useState(props.data || {});
  const loggedIn = getGlobalState("loggedIn");

  // const [view, setView] = useState(
  //   !props.data || !props.data._id ? 'edit' : 'view'
  // )
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value });
  };

  const delLog = (id, message) => {
    if (!id) return null;
    setRemoteCall("delTimeLog", id, message, cb => props.getReports());
  };
  const setLog = (details, message) => {
    const { start, end } = details;
    if (!start && !end) return null;
    console.log(details);
    setRemoteCall("setTimeLog", details, message, cb => props.getReports());
  };
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0">{"Edit Timelog"}</DrawerHeader>
        <DrawerBody overflow={"auto"}>
          {(details.type === "competition" ||
            details.task === "Competition") && (
            <EditTravels
              setLog={null}
              details={details}
              handleFormFieldChange={handleFormFieldChange}
              devices={details.devices || []}
              dontEditDevices={true}
            />
          )}
          {details.type !== "competition" && details.task !== "Competition" && (
            <RenderEditTimelog
              setLog={null}
              details={details}
              handleFormFieldChange={handleFormFieldChange}
              devices={details.devices || []}
              dontEditDevices={true}
              editConsumption={true}
            />
          )}
        </DrawerBody>
        <DrawerFooter borderTopWidth="0">
          {loggedIn && loggedIn.admin && (
            <Button
              variant="outline"
              colorScheme="primary"
              mr={3}
              onClick={() => {
                delLog(details._id, ` deleting user log`, "users");
                props.setOpenModal();
              }}
            >
              Delete
            </Button>
          )}
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancel
          </Button>
          <Button
            colorScheme="secondary"
            onClick={() => {
              setLog({ ...details }, ` updating user log`, "users");
              props.setOpenModal();
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
