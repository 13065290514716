import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import { colors } from "../../theme";
export const ListContainer = styled.ul`
  && {
    border: none;
    display: flex;
    justify-content: space-between;
  }
`;

export const ItemNumb = styled.span`
  && {
    background: #fff;
    right: 10px;

    font-size: 0.5em !important;
    padding: 0.5em;
    border-radius: 50%;
    position: absolute;
    color: #2d2e3c !important;
    display: block !important;
    min-width: 1.3em;
  }
`;

export const StyledFooter = styled.div`
  && {
    li {
      a {
        color: #fff;
      }
    }
  }
`;

export const StyledDrawer = styled.div`
  && {
    border: none;
    height: auto;
    border-radius: 0;
    /* box-shadow: 4px 0 9px 0 rgba(0, 0, 0, 0.06); */
    /* background: #ffffff40; */
    /* box-shadow: rgb(0 0 0 / 7%) 0px 3.5px 5.5px; */
    & > div {
      border: none;
      /* height: 80px; */
    }
    /* & > div > div {
      display: none;
    }
    &:hover > div > div {
      display: block;
    } */

    /* background: #d8d8d8; */
    /* background-image: linear-gradient(-31deg, #0f1217 0%, #262c3a 100%); */

    height: 70px;
    bottom: 1em;
    left: 15%;
    top: auto;
    border-radius: 15px;
    @media screen and (max-width: 768px) {
      /* background: rgba(15, 76, 58, 0.9); */
      /* background: rgba(255, 255, 255, 0.7); */
      background: ${colors.secondary[500]};
      width: 100%;
      border-radius: 0;
      box-shadow: rgb(0 0 0 / 16%) -1px -5px 5.5px;
      opacity: 1;
      /* .menuItems {
        svg,
        path,
        g {
          fill: ${colors.secondary[500]};
          color: ${colors.secondary[500]};
        }
      }

      .settings {
        svg,
        g {
          fill: ${colors.secondary[500]};
          color: ${colors.secondary[500]};
        }
      } */
    }

    @media print {
      display: none;
    }
  }
`;
export const StyledListItem = styled.li`
  && {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
    transition: all 0.2s ease-in;
    /* font-style: italic; */
    text-decoration: none;
    color: ${colors.secondary[500]};
    padding: 1em;
    opacity: 0.7;
    /* border-bottom: 1px solid ${colors.secondary[200]}; */
    i {
      position: absolute;
      background: ${colors.secondary[200]};
      color: white;
      display: inline-block;
      width: 20px;
      font-size: 0.8rem;
      height: 20px;
      border-radius: 50%;
      right: 6px;
    }
    &:last-child {
      border-bottom: none;
    }
    svg {
      display: inline-block;
    }

    &:hover {
      text-align: center;
      opacity: 1;
      color: white;
      fill: white;
      position: relative;
      text-decoration: none;
      i {
      }
    }

    ${(props) =>
      props.active &&
      css`
        text-align: center;
        position: relative;
        text-decoration: none;
        color: ${colors.secondary[500]};
        /* color: ${colors.secondary[500]}; */
        i {
          background: ${colors.secondary[500]};
        }
        span {
          color: white;
          opacity: 1;
          display: block;
          position: absolute;
        }
        @media screen and (max-width: 768px) {
          span {
            display: none;
          }
        }
      `}
    @media screen and (max-width: 768px) {
      height: 80px;
      padding: 10px;
    }
    /* @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      padding: 0;
      margin: 0 0.8em;
      border-bottom: none;
      height: auto;
      span {
        font-size: 0.6rem;
        display:none
      }
      &:focus {
        outline-offset: none;
      }
    } */
  }
`;

export const StyledActiveListItem = styled(Link)`
  && {
    text-align: center;
    position: relative;
    color: ${colors.secondary[500]};
    border-left: 6px solid #fff;
    display: flex;
    align-items: center;
    height: 70px;
    position: relative;
    justify-content: center;
    text-decoration: none;
    i {
      font-weight: bold;
      color: #fff;
    }
    @media screen and (max-width: 768px) {
      height: 70px;
    }
  }
`;

export const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 1.3em;
    text-align: center;
    padding: 0.4em 0;
    color: rgba(255, 255, 255, 1);
  }
`;

export const StyledLogo = styled(ListItem)`
  && {
    padding: 1em;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 3vh;
    z-index: 300;
    width: 160px;
    height: 80px;
    margin: 2% 0;
    background: url("/images/logoNav.svg");
    background-position: center;
    background-size: contain;
    /* border-bottom: 1px solid ${colors.primary[100]}; */
    background-repeat: no-repeat;
    display: block;
    margin-right: 2em;
    img {
      /* width: 45%; */
      max-height: 55px;
    }
    ${(props) => props.url && `background-image: url(${props.url});`}
    @media screen and (max-width: 770px) {
      top: 2vh;
      width: 100%;
      height: 80px;
      margin: 2% 0 6% 0;
    }
    @media screen and (max-width: 480px) {
      height: 70px;
      margin: 2% 0 2% 0;
    }
  }
`;

export const NumberToApprove = styled.span`
  && {
    background: #fff;
    border-radius: 50%;
    padding: 0.5em;
    font-size: 0.7em;
    position: absolute;
    top: 4px;
    right: -10px;
    font-weight: bold;
  }
`;

export const StyledListWrap = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
`;

// export const StyledList = styled.div`
//   && {
//     display: flex;
//     justify-content: space-around;
//     flex-direction: column;

//     height: 100%;
//     align-items: center;
//     align-content: center;
//     &:last-child {
//       border-bottom: none;
//     }
//     flex-direction: row;
//     width: 80%;
//   }
// `;

export const StyledNavigationPanel = styled.div`
  && {
    border: none;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    width: auto;
    right: 0;
    top: 5vh;
    height: 80px;

    @media screen and (max-width: 768px) {
      left: 0;
      position: fixed;
      right: auto;
      bottom: 0;
      top: auto;
      width: 100vw;
      height: auto;
      justify-content: center;
      backdrop-filter: saturate(100%) blur(10px);
    }
  }
`;
