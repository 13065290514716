import styled from "styled-components";
import { colors } from "../../theme";

export const ResultBoard = styled.div`
  && {
    position: relative;
    width: ${props => (props.width ? `${props.width}%` : `30%`)};
    color: #fff;
    border: 4px solid #fff7;
    cursor: pointer;
    padding: 1em 1em;
    margin: ${props => (props.margin ? `${props.margin}` : `1em 0`)};
    box-shadow: 5px 10px 10px 0 ${colors.primary.transparent[20]};

    background: #d8d8d8;
    max-width: ${props => (props.width ? `${props.width}%` : `30%`)};
    @media screen and (max-width: 768px) {
      width: 90%;
      max-width: 90%;
      margin: ${props =>
        props.mMargin ? `${props.mMargin}` : `0.5em  0 0 8%`};
    }

    background: #bbbbbb;
    background-image: linear-gradient(-29deg, #bbbbbb 0%, #ffffff 100%);
    box-shadow: 8px 10px 18px 0 rgba(0, 0, 0, 0.3),
      inset 1px 1px 1px 0 rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    /* clip-path: path('http://localhost:3001/images/boardMask.svg#boardMask'); */

    /* clip-path: path(
      'M23.1722138,43.652575 L614.172214,0.327761853 C627.942382,-0.681696602 639.923636,9.66290224 640.933094,23.4330701 C640.977683,24.0413121 641,24.6509821 641,25.2608563 L641,277.871552 C641,291.67867 629.807119,302.871552 616,302.871552 L25,302.871552 C11.1928813,302.871552 -2.91021396e-11,291.67867 -2.91038305e-11,277.871552 L-2.91038305e-11,68.5856694 C-2.91018818e-11,55.4876885 10.109286,44.6101873 23.1722138,43.652575 Z'
    ); */
    /* clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%); */
    /* background: url('images/boardMaks.svg') no-repeat; */
    transform: skew(0, -2deg) !important;
    border-radius: 20px;
    > div {
      /* transform: skew(0, 2deg) !important; */
    }
  }
`;
