import {
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Textarea,
  useToast,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Board } from "../components/styled";
import moment from "moment";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { setRemoteCall } from "../remote";
import { getGlobalState, useGlobalState } from "../store";

export default function EditOffsets(props) {
  const toast = useToast();
  // const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const [offsets, setOffsets] = useGlobalState("offsets");
  const clients = getGlobalState("clients");
  const [details, setDetails] = useState({
    id: null,
    date: new Date(),
    value: "",
    type: "general",
    status: 1,
    provider: "",
    description: "",
    client: "",
  });

  /**
   * handle change
   */
  const handleFormFieldChange = (e) => {
    if (!e.target.name) return null;
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  /**
   * Del Type
   */
  const delOffset = (id) => {
    setRemoteCall("delOffset", id, null);
  };
  /**
   * Set Type
   */
  const setOffset = () => {
    if (!details.value || details.vale === "") {
      return toast({
        title: "Error",
        description: "Please enter a value",
        status: "error",
      });
    }
    setRemoteCall("setOffsets", details, null);
  };

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0">Edit Offsets</DrawerHeader>

        <DrawerBody overflow={"auto"}>
          <Textarea
            name={"description"}
            padding={"1.5em .5em"}
            onChange={handleFormFieldChange}
            placeholder={"Details"}
            value={details.description}
          />
          <HStack m={".5em 0"}>
            <Input
              name={"date"}
              type={"date"}
              onChange={handleFormFieldChange}
              placeholder="Date of the offset"
            />
          </HStack>
          <HStack m={".5em 0"}>
            <Input
              name={"provider"}
              type={"text"}
              value={details.provider}
              onChange={handleFormFieldChange}
              placeholder="Provider"
            />
            <Select
              placeholder="Team"
              value={details.client}
              name={"client"}
              onChange={handleFormFieldChange}
            >
              {clients.map((client, i) => {
                return <option value={client._id}>{client.name}</option>;
              })}
            </Select>
          </HStack>
          <InputGroup size="lg">
            <Input
              name={"value"}
              type={"number"}
              value={details.value}
              onChange={handleFormFieldChange}
              placeholder="Offset amount in kg CO2 "
            />
            <InputRightElement width="4.5rem">
              <Button
                size="sm"
                colorScheme="primary"
                marginTop={".7rem"}
                onClick={() => setOffset()}
              >
                Add +
              </Button>
            </InputRightElement>
          </InputGroup>

          {offsets &&
            offsets
              ?.sort((a, b) => {
                //compare and sort a b date
                return new Date(b.date) - new Date(a.date);
              })
              ?.map((init, i) => {
                return (
                  <Board width={"100%"}>
                    <Flex
                      key={i}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Box fontWeight={"bold"} fontSize={"1.2em"}>
                          {init.value} kg CO2{" "}
                          <span style={{ color: "rgb(27 124 95)" }}>
                            @ {moment(init.date).format("YYYY/MM/DD")}
                          </span>
                        </Box>

                        <Box fontSize={".8em"}>{init.description}</Box>
                      </Box>
                      <Flex>
                        <Button
                          size="sm"
                          colorScheme="primary"
                          variant="outline"
                          onClick={() => delOffset(init._id)}
                        >
                          -
                        </Button>
                      </Flex>
                    </Flex>
                  </Board>
                );
              })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="0">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
